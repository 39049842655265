import { FormikErrors } from "formik";
import React from "react";
import { Template } from "../../../models";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

type EmbedMediaProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

const EmbedMedia = ({ values, setFieldValue }: EmbedMediaProps) => {
  return (
    <TemplateContainer>
      <SwitchContainer
        title="Embed the video into the Article"
        subTitle=""
        tooltip="This will embed the video right into the article."
        enabled={!!values?.include_web_embed}
        setEnabled={() =>
          setFieldValue("include_web_embed", !values?.include_web_embed)
        }
      />
    </TemplateContainer>
  );
};

export default EmbedMedia;
