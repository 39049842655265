import { FormikErrors } from "formik";
import React from "react";
import SelectV2, { OptionType } from "../../../components/CustomSelect";
import {
  BrandPersonaEnum,
  BrandToneEnum,
  BrandVoiceEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import TemplateContainer from "./common/TemplateContainer";

const brandPersonaOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: BrandPersonaEnum.PROFESSIONAL,
    label: "Professional",
    description: "Knowledgeable, pragmatic, supportive, and impartial",
  },
  {
    value: BrandPersonaEnum.EXPERT,
    label: "Expert",
    description: "Knowledgeable, authoritative, and confident",
  },
  {
    value: BrandPersonaEnum.PHILOSOPHER,
    label: "Philosopher",
    description: "Ethical, thought-provoking, encourages critical thinking",
  },
  {
    value: BrandPersonaEnum.FRIEND,
    label: "Friend",
    description: "Warm, approachable, and relatable",
  },
  {
    value: BrandPersonaEnum.ENTERTAINER,
    label: "Entertainer",
    description: "Fun, humorous, and engaging",
  },
  {
    value: BrandPersonaEnum.SAGE,
    label: "Sage",
    description: "Wise, insightful, and thought-provoking",
  },
  {
    value: BrandPersonaEnum.REBEL,
    label: "Rebel",
    description: "Bold, daring, and unconventional",
  },
  {
    value: BrandPersonaEnum.CAREGIVER,
    label: "Caregiver",
    description: "Nurturing, supportive, and empathetic",
  },
];

const brandVoiceOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: BrandVoiceEnum.PROFESSIONAL,
    label: "Professional",
    description: "Formal, polished, and business-like",
  },
  {
    value: BrandVoiceEnum.CASUAL,
    label: "Casual",
    description: "Informal, conversational, and relaxed",
  },
  {
    value: BrandVoiceEnum.ENTHUSIASTIC,
    label: "Enthusiastic",
    description: "Energetic, passionate, and upbeat",
  },
  {
    value: BrandVoiceEnum.STRAIGHTFORWARD,
    label: "Straightforward",
    description: "Direct, clear, and to-the-point",
  },
  {
    value: BrandVoiceEnum.WITTY,
    label: "Witty",
    description: "Clever, humorous, and sharp",
  },
  {
    value: BrandVoiceEnum.EMPOWERING,
    label: "Empowering",
    description: "Inspiring, motivating, and encouraging",
  },
  {
    value: BrandVoiceEnum.INTELLECTUAL,
    label: "Intellectual",
    description: "Sophisticated, academic, and well-informed",
  },
  {
    value: BrandVoiceEnum.MOTIVATIONAL,
    label: "Motivational",
    description:
      "Uplifting, aspirational, focuses on potential and bigger picture",
  },
];

const brandToneOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: BrandToneEnum.INFORMATIVE,
    label: "Informative",
    description: "Educational, instructive, and explanatory",
  },
  {
    value: BrandToneEnum.PERSUASIVE,
    label: "Persuasive",
    description: "Convincing, compelling, and influential",
  },
  {
    value: BrandToneEnum.PLAYFUL,
    label: "Playful",
    description: "Light-hearted, whimsical, and amusing",
  },
  {
    value: BrandToneEnum.FRIENDLY,
    label: "Friendly",
    description: "Amiable, warm, cordial, and welcoming",
  },
  {
    value: BrandToneEnum.INSPIRING,
    label: "Inspiring",
    description: "Stimulating, thought-provoking, and interesting",
  },
  {
    value: BrandToneEnum.ASPIRATIONAL,
    label: "Aspirational",
    description: "Ambitious, goal-oriented, and uplifting",
  },
  {
    value: BrandToneEnum.PASSIONATE,
    label: "Passionate",
    description: "Enthusiastic, High-Energy, and inspiring a sense of momentum",
  },
];

type BrandSettingsProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

const BrandSettings = ({ values, setFieldValue }: BrandSettingsProps) => {
  return (
    <div className="space-y-6 animate-fadeIn">
      <TemplateContainer>
        <div className="mb-6">
          <SelectV2
            label="Brand Persona"
            placeholder="-"
            options={brandPersonaOptions}
            selected={brandPersonaOptions.find(
              (option) => option.value === values?.brand_persona_id
            )}
            tooltip="Refers to the identity, personality traits, and characteristics that your brand embodies."
            setSelected={(option) => {
              setFieldValue(
                "brand_persona_id",
                option?.value as BrandPersonaEnum
              );
            }}
          />
        </div>
        <div className="mb-6">
          <SelectV2
            label="Brand Voice"
            placeholder="-"
            options={brandVoiceOptions}
            selected={brandVoiceOptions.find(
              (option) => option.value === values?.brand_voice_id
            )}
            tooltip="The distinct style or manner of communication that your brand uses consistently across all its messaging and content."
            setSelected={(option) => {
              setFieldValue("brand_voice_id", option?.value as BrandVoiceEnum);
            }}
          />
        </div>
        <SelectV2
          label="Brand Tone"
          placeholder="-"
          options={brandToneOptions}
          selected={brandToneOptions.find(
            (option) => option.value === values?.brand_tone_id
          )}
          tooltip="The attitude or emotional flavor applied to the language used in communication. This may vary depending on the context."
          setSelected={(option) => {
            setFieldValue("brand_tone_id", option?.value as BrandToneEnum);
          }}
        />
      </TemplateContainer>
    </div>
  );
};

export default BrandSettings;
