import { RoleEnum } from "../enums/user";
export interface Account {
  company_name?: string;
  company_logo_url?: string;
  timezone?: string;
  country_code?: string;
  account_id: string;
  role_id: RoleEnum;
  stripe_customer_id?: string;
}
export class User {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  profile_picture_url: string;
  bio: string;
  accounts: Account;
  constructor({
    user_id,
    email,
    first_name,
    last_name,
    profile_picture_url,
    accounts,
    bio,
  }: any) {
    this.user_id = user_id;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.profile_picture_url = profile_picture_url;
    this.accounts = accounts;
    this.bio = bio;
  }
}
