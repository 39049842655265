import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../models/template";
import {
  browserTimeZone,
  convertReelsToReelInURL,
  getInvalidMediaLinkErrorMessage,
  isValidMediaLink,
  removeURLQueryParams,
  removeURLQueryParamsExceptForV,
} from "../../utils";
import Setup from "./components/Setup";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  AdjustmentsHorizontalIcon,
  ArrowUpOnSquareIcon,
  Cog6ToothIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useSearchParam } from "react-use";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import { ROUTE_TEMPLATES } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import {
  ArticleTypeEnum,
  PointOfViewEnum,
  SourceEnum,
  TemplateStatusEnum,
  TranscribeSubjectEnum,
} from "../../enums/template";
import { setUpgradeModal } from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useGetDashboardQuery } from "../../redux/services/dashboard";
import {
  useCreateTemplateMutation,
  useGetTemplateByIdQuery,
  useUpdateTemplateByIdMutation,
} from "../../redux/services/templates";
import { useGetUserQuery } from "../../redux/services/user";
import AdvancedSettings from "./components/AdvancedSettings";
import DestinationSettings from "./components/DestinationSettings";
import SideNav from "./components/SideNav";

export enum TemplateStepEnum {
  SETUP = "setup",
  ADVANCED_SETTINGS = "advanced-settings",
  DESTINATION = "destination",
}

export const TemplateSteps = [
  {
    name: TemplateStepEnum.SETUP,
    title: "Setup",
    icon: <AdjustmentsHorizontalIcon className="w-5 mr-1" />,
  },
  {
    name: TemplateStepEnum.ADVANCED_SETTINGS,
    title: "Advanced Settings",
    icon: <Cog6ToothIcon className="w-5 mr-1" />,
  },
  {
    name: TemplateStepEnum.DESTINATION,
    title: "Destination",
    icon: <ArrowUpOnSquareIcon className="w-5 mr-1" />,
  },
];

const initialValues: Partial<Template> = {
  // base
  name: "",
  source_id: SourceEnum.INSTAGRAM_REELS,
  template_status_id: TemplateStatusEnum.PENDING,
  // completed_at: ""

  // auto
  is_auto_enabled: false,
  profile_url: null,
  auto_days: [],
  auto_time1: null,
  auto_time2: null,
  timezone: "", // browser

  // base prompt
  media_url: "",
  media_author_name: "",
  bio: "", // user.bio
  include_intro: true,
  article_length: null,
  temperature: 0.5,
  point_of_view_id: PointOfViewEnum.THIRD_PERSON,
  include_web_embed: true,
  include_credits: false,

  // brand
  brand_persona_id: null,
  brand_voice_id: null,
  brand_tone_id: null,
  transcribe_subject_id: TranscribeSubjectEnum.GENERAL,

  // image
  include_image: false,
  image_size_id: null,
  image_style_id: null,
  image_tone_id: null,

  // FAQ
  include_faq: false,
  faq_count: null,
  faq_question_prefix_id: null,

  // Destination
  destination_id_list: null,
  destination_id: null,

  // Article Type
  article_type_id: ArticleTypeEnum.NONE,
};

const TemplateCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { id: templateId } = useParams(); // EDIT
  const redirectToStep = useSearchParam("redirect_to_step");

  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id;
  const { data: user } = useGetUserQuery();
  const { data: template, refetch } = useGetTemplateByIdQuery(
    {
      account_id: accountId,
      template_id: templateId,
    },
    { skip: !templateId }
  );

  const [
    createTemplate,
    {
      isLoading: loadingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
      error: errorCreate,
    },
  ] = useCreateTemplateMutation(); // CREATE
  const [
    updateTemplateById,
    {
      isLoading: loadingEdit,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateTemplateByIdMutation(); // EDIT

  const { data: dashboardData } = useGetDashboardQuery(
    {
      account_id: accountId || "",
      timezone: selectedAccount?.timezone || browserTimeZone,
    },
    { skip: !accountId }
  );

  const [step, setStep] = useState(TemplateStepEnum.SETUP);
  const [formValues, setFormValues] = useState<Template>();

  useEffect(() => {
    if (templateId) {
      refetch();
    }
  }, [templateId, refetch]);

  useEffect(() => {
    if (template) {
      setFormValues(
        new Template({
          ...template,
          destination_id:
            template?.destination_id || template?.destination_id_list?.[0],
        })
      );
    } else {
      setFormValues(
        new Template({
          ...initialValues,
          bio: user?.bio || `${user?.first_name} ${user?.last_name}` || "",
          transcribe_subject_id: TranscribeSubjectEnum.GENERAL,
          timezone: user?.accounts?.[0]?.timezone || browserTimeZone,
        })
      );
    }
  }, [template, user]);

  useEffect(() => {
    if (redirectToStep && redirectToStep === TemplateStepEnum.DESTINATION) {
      setStep(TemplateStepEnum.DESTINATION);
    }
  }, [redirectToStep]);

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      toast.success(
        `Successfully ${isSuccessCreate ? "created" : "updated"} template.`
      );
      navigate(ROUTE_TEMPLATES);
    }
  }, [isSuccessCreate, isSuccessUpdate, navigate]);

  useEffect(() => {
    if (isErrorCreate || isErrorUpdate || errorCreate || errorUpdate) {
      toast.error(
        ((errorCreate || errorUpdate) as any)?.data?.detail ||
        "Something went wrong! Please try again."
      );
    }
  }, [isErrorCreate, isErrorUpdate, errorCreate, errorUpdate]);

  const handleSubmit = async (values: any) => {
    setFormValues({ ...formValues, ...values });

    let payload = {
      account_id: accountId,
      ...values,
    };

    delete payload["destination_id_list"];

    if (payload["include_image"] === false) {
      payload["image_size_id"] = null;
      payload["image_style_id"] = null;
      payload["image_tone_id"] = null;
    }

    if (!payload["include_faq"]) {
      payload["faq_question_prefix_id"] = null;
      payload["faq_count"] = null;
    }

    if (payload["is_auto_enabled"]) {
      payload["media_url"] = null;
    } else {
      payload["username"] = null;
    }

    // Only for Instagram Reels
    if (
      !payload["is_auto_enabled"] &&
      payload["source_id"] === SourceEnum.INSTAGRAM_REELS
    ) {
      if (payload["media_url"]?.indexOf("?") !== -1) {
        payload["media_url"] = removeURLQueryParams(payload["media_url"]);
      }

      if (payload["media_url"]?.indexOf("reels") !== -1) {
        payload["media_url"] = convertReelsToReelInURL(payload["media_url"]);
      }
    }

    if (
      !payload["is_auto_enabled"] &&
      payload["source_id"] === SourceEnum.YOUTUBE_VIDEOS
    ) {
      if (payload["media_url"]?.indexOf("?") !== -1) {
        payload["media_url"] = removeURLQueryParamsExceptForV(
          payload["media_url"]
        );
      }
    }

    // Update
    if (templateId) {
      payload = {
        ...payload,
        template_id: templateId,
      };

      if (template?.destination_id_list?.[0] !== payload["destination_id"]) {
        payload["old_destination_id"] = template.destination_id_list[0];

        // Set destination_id to null if it doesn't exist in payload
        payload["destination_id"] = payload["destination_id"] || null;
      }

      await updateTemplateById(payload)
        .unwrap()
        .catch(() => { });
    } else {
      // Create
      await createTemplate(payload)
        .unwrap()
        .catch(() => { });
    }
  };

  return (
    <SpringContainer className="overflow-y-auto overflow-x-hidden">
      <Formik
        enableReinitialize={true}
        initialValues={formValues!}
        validateOnChange={false}
        validate={(values: any) => {
          const errors: any = {};
          const { name, media_url, source_id, is_auto_enabled, username } =
            values;
          if (!name) {
            errors["name"] = "Name is required";
          }

          if (is_auto_enabled && !username) {
            errors["username"] = "Username is required";
          }

          if (!is_auto_enabled) {
            if (!media_url) {
              errors["media_url"] = "Media link is required";
            } else if (!isValidMediaLink(media_url, source_id)) {
              errors["media_url"] = getInvalidMediaLinkErrorMessage(source_id);
            }
          }

          return errors;
        }}
        onSubmit={(values) => {
          if (values?.template_status_id === TemplateStatusEnum.QUEUED) {
            return;
          }
          if (dashboardData?.tokens_remaining < 1) {
            dispatch(setUpgradeModal(true));
            return;
          }
          handleSubmit(values);
        }}
      >
        {(props: FormikProps<any>) => {
          const { values, errors, validateForm, setFieldValue, submitForm } =
            props;
          const isAuto = values?.is_auto_enabled;
          const loading = Boolean(loadingCreate || loadingEdit);
          let disabled = false;
          if (values?.template_status_id === TemplateStatusEnum.QUEUED) {
            disabled = true;
          }
          return (
            <Form>
              <div className="flex flex-col xl:flex-row xl:space-x-6">
                <SideNav {...{ step, setStep, validateForm }} />
                <div className="w-full ltr:mr-2 rtl:ml-2 mt-10">
                  {step === TemplateStepEnum.SETUP && (
                    <Setup
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {step === TemplateStepEnum.ADVANCED_SETTINGS && (
                    <AdvancedSettings
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {step === TemplateStepEnum.DESTINATION && (
                    <DestinationSettings
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  <div className="flex flex-col gap-6 my-6">
                    {step === TemplateStepEnum.ADVANCED_SETTINGS && (
                      <Button
                        paddingZero
                        variant="transparent-primary"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          validateForm().then((formErrors: any) => {
                            if (Object.keys(formErrors).length === 0) {
                              setStep(TemplateStepEnum.DESTINATION);
                            }
                          });
                        }}
                        className={classNames("min-w-24 w-max")}
                      >
                        <span className="flex items-center uppercase text-xs font-semibold tracking-wide">
                          Destination{" "}
                          <ChevronRightIcon className="ml-0.5 text-primary size-4" />
                        </span>
                      </Button>
                    )}
                    {step === TemplateStepEnum.SETUP && (
                      <Button
                        paddingZero
                        variant="transparent-primary"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          validateForm().then((formErrors: any) => {
                            if (Object.keys(formErrors).length === 0) {
                              setStep(TemplateStepEnum.ADVANCED_SETTINGS);
                            }
                          });
                        }}
                        className={classNames("min-w-24 w-max")}
                      >
                        <span className="flex items-center uppercase text-xs font-semibold tracking-wide">
                          Advanced Settings{" "}
                          <ChevronRightIcon className="ml-0.5 text-primary size-4" />
                        </span>
                      </Button>
                    )}

                    {disabled && (
                      <div className="text-xs text-red-600 uppercase tracking-wide flex gap-1">
                        <ExclamationTriangleIcon className="size-4 text-red-600" />
                        This template is currently generating.
                      </div>
                    )}
                    <div className="flex items-center gap-3">
                      {!isAuto && (
                        <>
                          <Button
                            variant={disabled ? "disabled" : "primary"}
                            onClick={() => {
                              setFieldValue(
                                "template_status_id",
                                TemplateStatusEnum.DRAFT
                              );
                              submitForm();
                            }}
                            className="min-w-24"
                          >
                            {loading ? <LoadingSpinner /> : "Save"}
                          </Button>
                          <Button
                            variant={disabled ? "disabled" : "light-primary"}
                            onClick={() => {
                              setFieldValue(
                                "template_status_id",
                                TemplateStatusEnum.PENDING
                              );
                              submitForm();
                            }}
                            className="min-w-24"
                          >
                            {loading ? <LoadingSpinner /> : "Generate Now"}
                          </Button>
                        </>
                      )}
                      {isAuto && (
                        <Button
                          variant={disabled ? "disabled" : "primary"}
                          onClick={() => {
                            setFieldValue(
                              "template_status_id",
                              TemplateStatusEnum.PENDING
                            );
                            submitForm();
                          }}
                          className="min-w-24"
                        >
                          {loading ? <LoadingSpinner /> : "Save and Close"}
                        </Button>
                      )}
                      <Button
                        variant="default"
                        type="button"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          navigate(ROUTE_TEMPLATES);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SpringContainer>
  );
};

export default TemplateCreate;
