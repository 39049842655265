import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

const TemplateSideNavItem = ({
  title,
  icon,
  current,
  onClick,
}: {
  title: string;
  icon: any;
  current: boolean;
  onClick: (e: any) => void;
}) => {
  return (
    <button
      type="button"
      className={classNames(
        "cursor-pointer text-default text-sm font-medium leading-none min-h-12 items-center",
        "group-hover:text-default group flex w-64 flex-row",
        "rounded-md px-3 py-3 transition h-auto items-center",
        { "hover:bg-gray-100": !current },
        { "bg-gray-100": current }
      )}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {icon}
      <div className="h-fit">
        <span className="flex items-center">
          <div
            className="max-w-36 min-h-4 truncate mt-0.5"
            title="Event Setup"
          >
            {title}
          </div>
        </span>
      </div>
      {current && (
        <div className="ml-auto self-center">
          <ChevronRightIcon className="w-4 h-4" />
        </div>
      )}
    </button>
  );
};
export default TemplateSideNavItem;
