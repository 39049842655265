import { generatePath } from "react-router-dom";
import { SUBSCRIPTION } from "../apiEndPoints";
import { api } from "./api";

export const subscriptionApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    // getActiveSubscription: builder.query({
    //   query: ({ account_id }) => ({
    //     url: generatePath(ACTIVE_SUBSCRIPTION, { account_id }),
    //     method: "GET",
    //   }),
    // }),
    getSubscription: builder.query({
      query: ({ account_id }) => ({
        url: generatePath(SUBSCRIPTION, { account_id }),
        method: "GET",
      }),
    }),
  }),
});

export const {
  // useGetActiveSubscriptionQuery,
  useGetSubscriptionQuery,
} = subscriptionApiSlice;
