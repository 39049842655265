import { generatePath } from "react-router-dom";
import {
  DESTINATIONS,
  DESTINATION_ATTACH_TO_TEMPLATE,
  DESTINATION_BY_ID,
  DESTINATION_TEST,
} from "../apiEndPoints";
import { api } from "./api";

export const destinationApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createDestination: builder.mutation({
      query: ({ account_id, ...rest }) => ({
        url: generatePath(DESTINATIONS, { account_id }),
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Destinations"],
    }),
    getDestinations: builder.query({
      query: ({ account_id, offset, limit, include_total_count, search }) => {
        let url =
          generatePath(DESTINATIONS, { account_id }) +
          `?offset=${offset}&limit=${limit}&include_total_count=${include_total_count}`;
        if (search) {
          url += `&search=${search}`;
        }
        return {
          url,
          method: "GET",
        };
      },
      providesTags: (result, error, { account_id }) => [
        { type: "Destinations", id: account_id },
      ],
    }),
    getDestinationById: builder.query({
      query: ({ account_id, destination_id }) => ({
        url: generatePath(DESTINATION_BY_ID, { account_id, destination_id }),
        method: "GET",
      }),
      providesTags: (result, error, { account_id }) => [
        { type: "Destinations", id: account_id },
      ],
    }),
    updateDestinationById: builder.mutation({
      query: ({ account_id, destination_id, ...rest }) => ({
        url: generatePath(DESTINATION_BY_ID, { account_id, destination_id }),
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Destinations"],
    }),
    deleteDestinationById: builder.mutation({
      query: ({ account_id, destination_id }) => ({
        url: generatePath(DESTINATION_BY_ID, { account_id, destination_id }),
        method: "DELETE",
      }),
      invalidatesTags: ["Destinations"],
    }),
    testDestination: builder.mutation({
      query: ({ account_id, ...rest }) => ({
        url: generatePath(DESTINATION_TEST, { account_id }),
        method: "POST",
        body: rest,
      }),
    }),
    attachDestinationToTemplate: builder.mutation({
      query: ({ account_id, destination_id, template_id }) => ({
        url: generatePath(DESTINATION_ATTACH_TO_TEMPLATE, {
          account_id,
          destination_id,
          template_id,
        }),
        method: "POST",
      }),
    }),
    removeDestinationFromTemplate: builder.mutation({
      query: ({ account_id, destination_id, template_id }) => ({
        url: generatePath(DESTINATION_ATTACH_TO_TEMPLATE, {
          account_id,
          destination_id,
          template_id,
        }),
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateDestinationMutation,
  useGetDestinationsQuery,
  useGetDestinationByIdQuery,
  useUpdateDestinationByIdMutation,
  useDeleteDestinationByIdMutation,
  useTestDestinationMutation,
  useAttachDestinationToTemplateMutation,
  useRemoveDestinationFromTemplateMutation,
} = destinationApiSlice;
