import { TrashIcon } from "@heroicons/react/24/outline";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import InfoTooltip from "../../components/InfoTooltip";
import Input from "../../components/Input";
import PageHeading from "../../components/PageHeading";
import RadioGroup, { Option } from "../../components/RadioGroup";
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import { Text } from "../../components/Typography";
import { ROUTE_DESTINATIONS } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import {
  DestinationStatusEnum,
  DestinationTypeEnum,
} from "../../enums/destination";
import useSelectedAccount from "../../hooks/useSelectedAccount";
import { Destination } from "../../models/destination";
import {
  useCreateDestinationMutation,
  useGetDestinationByIdQuery,
  useTestDestinationMutation,
  useUpdateDestinationByIdMutation,
} from "../../redux/services/destinations";
import { ensureTrailingSlash } from "../../utils";
import TemplateContainer from "../TemplateCreate/components/common/TemplateContainer";
import DestinationDeleteModal from "./components/DestinationDeleteModal";
import DestinationSaveModal from "./components/DestinationSaveModal";
import WordpressAppPassGuide from "./components/WordpressAppPassGuide";

const wordpressSchema = Yup.object().shape({
  name: Yup.string().required("Destination name is required"),
  destination: Yup.object().shape({
    url: Yup.string().required("Wordpress URL is required"),
    username: Yup.string().required("Admin Login Username is required"),
    // author_id: Yup.string().required("Author ID is required"),
    // category_id: Yup.string().required("Category ID is required"),
    secret_key: Yup.string()
      .required("App password is required")
      .test(
        "no-asterisk",
        "Please verify your app password",
        (value) => !value || !value.includes("**********")
      ),
  }),
});

const initialValues: Destination = {
  name: "",
  destination_type_id: DestinationTypeEnum.WORDPRESS,
  destination: {
    url: "",
    username: "",
    author_id: undefined,
    category_id: undefined,
    secret_key: "",
    status: DestinationStatusEnum.DRAFT,
  },
};

const statusOptions: Option[] = [
  {
    id: DestinationStatusEnum.DRAFT,
    name: "draft",
  },
  {
    id: DestinationStatusEnum.PENDING,
    name: "pending",
  },
  {
    id: DestinationStatusEnum.PUBLISHED,
    name: "published",
  },
];

const DestinationConnect = () => {
  let { id: destinationId } = useParams(); // EDIT

  const navigate = useNavigate();
  const { accountId } = useSelectedAccount();

  const [
    createDestination,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: isErrorCreate,
      error: errorCreate,
    },
  ] = useCreateDestinationMutation();
  const [
    updateDestination,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateDestinationByIdMutation();
  const [
    testDestination,
    {
      isLoading: testLoading,
      isError: isErrorTest,
      data: testSuccessData,
      error: errorMessageTest,
    },
  ] = useTestDestinationMutation();

  const { data: destination, refetch } = useGetDestinationByIdQuery(
    {
      account_id: accountId,
      destination_id: destinationId,
    },
    { skip: !destinationId }
  );

  const [formValues, setFormValues] = useState<Destination>();
  const [passwordGuide, setPasswordGuide] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    if (destinationId) {
      refetch();
    }
  }, [destinationId, refetch]);

  useEffect(() => {
    setFormValues(
      new Destination(
        destinationId && destination ? destination : initialValues
      )
    );
  }, [destinationId, destination]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate(ROUTE_DESTINATIONS);
    }
  }, [createSuccess, updateSuccess, navigate]);

  useEffect(() => {
    if (isErrorCreate || isErrorUpdate || errorCreate || errorUpdate) {
      toast.error(
        ((errorCreate || errorUpdate) as any)?.data?.detail ||
          "Something went wrong."
      );
    }
  }, [isErrorCreate, isErrorUpdate, errorCreate, errorUpdate]);

  const handleSubmit = async (values: Destination, type: "test" | "save") => {
    let payload: Partial<Destination & { account_id: string }> = {
      destination_type_id: DestinationTypeEnum.WORDPRESS,
      name: values.name,
      destination: {
        url: ensureTrailingSlash(values.destination.url),
        username: values.destination.username,
        author_id: values.destination.author_id || null,
        category_id: values.destination.category_id || null,
        secret_key: values.destination.secret_key,
        status: values.destination.status,
      },
      account_id: accountId,
    };

    try {
      if (type === "test") {
        setFormValues(values);
        await testDestination(payload)
          .unwrap()
          .then(() => {
            setShowSaveModal(true);
          });
      } else {
        if (destinationId) {
          payload["destination_id"] = destinationId;
          payload["template_id_list"] = values.template_id_list || [];
          await updateDestination(payload).unwrap();
        } else {
          await createDestination(payload).unwrap();
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  if (destinationId && !destination) {
    return null;
  }

  return (
    <SpringContainer className="animate-fadeIn">
      {formValues && (
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={(values: Destination) => {
            if (values?.destination?.url) {
              values["destination"]["url"] = values?.destination?.url?.replace(
                /\/+$/,
                ""
              );
            }
            handleSubmit(values, "save");
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={wordpressSchema}
        >
          {(props) => {
            const { values, errors, validateForm, setFieldValue } = props;
            return (
              <Form
                className="flex flex-col gap-6 flex-wrap"
                noValidate
              >
                <TemplateContainer
                  className="max-w-[60.5rem] rounded"
                  style={{ padding: 0 }}
                >
                  <div className="py-4 px-4 sm:px-6 relative bg-gray-50 rounded-t-lg">
                    <PageHeading heading="Configure WordPress" />
                    <Text className="text-gray-500 text-sm">
                      Provide your account details to establish a connection
                    </Text>
                    {destinationId && (
                      <Button
                        variant="light-danger"
                        tooltip="Delete wordpress connection"
                        className="group hover:border-red-500 hover:bg-red-50 absolute top-4 right-3"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <TrashIcon className="size-5 text-red-500" />
                      </Button>
                    )}
                  </div>
                  <div className="bg-gray-200 h-px w-full" />
                  <div className="py-4 px-4 sm:px-6 flex flex-col gap-6 mb-4">
                    <Input
                      id="name"
                      label="Destination Name"
                      name="name"
                      type="text"
                      required
                      placeholder="Name your destination"
                      className="max-w-md"
                      tooltip="Name your destination (e.g., “My WordPress Site”)"
                      value={values.name}
                      errorMessage={errors?.name}
                      errorPosition="bottom-left"
                      onChange={(e: any) =>
                        setFieldValue("name", e.target.value)
                      }
                    />

                    <Input
                      id="url"
                      label="URL"
                      name="destination.url"
                      type="text"
                      required
                      placeholder="https://mydomain.com"
                      className="max-w-md"
                      tooltip="The root domain for your WordPress Site (e.g. “https://mydomain.com”)"
                      value={values.destination.url}
                      errorMessage={errors?.destination?.url}
                      errorPosition="bottom-left"
                      onChange={(e: any) =>
                        setFieldValue("destination.url", e.target.value)
                      }
                    />

                    <div className="flex flex-col md:flex-row gap-4">
                      <Input
                        id="username"
                        label="Admin Login Username"
                        name="destination.username"
                        type="text"
                        required
                        placeholder="Your WordPress username"
                        className="max-w-md"
                        tooltip="Your WordPress username"
                        value={values.destination.username}
                        errorMessage={errors?.destination?.username}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue("destination.username", e.target.value)
                        }
                      />
                      <div className="relative w-full">
                        <Input
                          id="secret_key"
                          label="App Password"
                          name="secret_key"
                          type="text"
                          required
                          placeholder="Password generated for API usage"
                          className="max-w-md"
                          tooltip="IMPORTANT: This is not your WordPress login password, rather a separate password generated for API usage."
                          value={values.destination.secret_key}
                          errorMessage={errors?.destination?.secret_key}
                          errorPosition="bottom-left"
                          onChange={(e: any) =>
                            setFieldValue(
                              "destination.secret_key",
                              e.target.value
                            )
                          }
                        />
                        <Button
                          paddingZero
                          type="button"
                          variant="transparent-primary"
                          className="mt-1"
                          onClick={() => setPasswordGuide(!passwordGuide)}
                        >
                          How to generate
                        </Button>
                      </div>
                    </div>
                    {passwordGuide && (
                      <WordpressAppPassGuide
                        onRequestClose={() => setPasswordGuide(false)}
                      />
                    )}
                    <div className="flex flex-col md:flex-row gap-4">
                      <Input
                        id="author_id"
                        label="Author ID"
                        name="destination.author_id"
                        type="number"
                        tooltip='This is the WordPress Author ID (a number) to whom you want the posts associated with. You can access this ID using a WordPress plugin such as "Reveal IDs."'
                        placeholder="WordPress Author ID (a number)"
                        className="max-w-md"
                        value={values?.destination?.author_id || ""}
                        errorMessage={errors?.destination?.author_id}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue("destination.author_id", e.target.value)
                        }
                      />

                      <Input
                        id="category_id"
                        label="Category ID"
                        name="destination.category_id"
                        type="number"
                        tooltip='This is the WordPress Category ID (a number) to assign your posts a category. You can access this ID using a WordPress plugin such as "Reveal IDs."'
                        placeholder="WordPress Category ID (a number)"
                        className="max-w-md"
                        value={values?.destination?.category_id || ""}
                        errorMessage={errors?.destination?.category_id}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue(
                            "destination.category_id",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="w-max">
                      <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
                        Status
                        <InfoTooltip id="subject">
                          <div className="flex flex-col gap-2">
                            <div>
                              <b>draft:</b> Save the post as a draft — only
                              visible to users with admin access to the blog.
                            </div>
                            <div>
                              <b>pending:</b> Mark the post as pending editorial
                              approval.
                            </div>
                            <div>
                              <b>publish:</b> Publish the post — visible to
                              anyone with access to the blog.
                            </div>
                          </div>
                        </InfoTooltip>
                      </h3>
                      <RadioGroup
                        options={statusOptions}
                        name="status"
                        selected={statusOptions.find(
                          ({ id }) => id === values?.destination?.status
                        )}
                        onClick={({ id }: Option) =>
                          setFieldValue("destination.status", id)
                        }
                      />
                    </div>
                  </div>
                </TemplateContainer>
                {isErrorTest && (
                  <div className="text-danger text-sm my-1">
                    {(errorMessageTest as any)?.data?.detail ||
                      "Wordpress plugins or security may be interfering with your site connection. Please check your settings."}
                  </div>
                )}
                <div className="flex items-center justify-between max-w-5xl">
                  <div className="flex items-center gap-4">
                    <Button
                      variant="primary"
                      className="min-w-[8.75rem]"
                      onClick={() => {
                        validateForm().then(async (formErrors: any) => {
                          if (Object.keys(formErrors).length === 0) {
                            handleSubmit(values, "test");
                          }
                        });
                      }}
                    >
                      {testLoading ? (
                        <LoadingSpinner size="18px" />
                      ) : (
                        "Test Connection"
                      )}
                    </Button>
                    <Button
                      variant="default"
                      onClick={() => {
                        navigate(ROUTE_DESTINATIONS);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      {showDeleteModal && (
        <DestinationDeleteModal
          destinationId={destinationId}
          onRequestClose={() => setShowDeleteModal(false)}
        />
      )}
      {showSaveModal && (
        <DestinationSaveModal
          message={testSuccessData?.message || ""}
          loading={createLoading || updateLoading}
          onSave={() => handleSubmit(formValues!, "save")}
          onRequestClose={() => setShowSaveModal(false)}
        />
      )}
    </SpringContainer>
  );
};

export default DestinationConnect;
