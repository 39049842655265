export enum RoleEnum {
  OWNER = 1,
  ADMIN = 2,
  VIEWER = 3,
}

export const RoleMap = {
  [RoleEnum.OWNER]: "Owner",
  [RoleEnum.ADMIN]: "Admin",
  [RoleEnum.VIEWER]: "Viewer",
};
