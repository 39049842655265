import {
  BASE_URL,
  STRIPE_CREATE_CUSTOMER_PORTAL_SESSION,
  STRIPE_CREATE_CUSTOMER_SESSION,
} from "./redux/apiEndPoints";

export const getStripeBillingURL = async (
  customerId: string,
  token: string
) => {
  let redirectUrl = "";
  await fetch(
    `${BASE_URL}${STRIPE_CREATE_CUSTOMER_PORTAL_SESSION}?customer_id=${customerId}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then(async (res: any) => {
      redirectUrl = res.redirect_url;
    });
  return redirectUrl;
};

export const createCustomerSession = async (
  customerId: string,
  token: string
) => {
  return await fetch(
    `${BASE_URL}${STRIPE_CREATE_CUSTOMER_SESSION}?customer_id=${customerId}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => res.json());
};
