import React from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";
import "./Slider.css";

const Slider: React.FC<ReactSliderProps> = ({ ...sliderProps }) => {
  return (
    <ReactSlider
      className="slider flex items-center"
      renderTrack={(props: any, state: any) => (
        <div
          {...props}
          className={`slider-track rounded ${state.index === 0 ? "bg-gray-300" : "bg-blue-500"}`}
        />
      )}
      renderThumb={(props: any, state: any) => (
        <div
          {...props}
          className="slider-thumb rounded-full bg-blue-600 cursor-pointer text-xs font-bold text-white flex items-center justify-center"
        >
          <div>{state.valueNow}</div>
        </div>
      )}
      {...sliderProps}
    />
  );
};

export default Slider;
