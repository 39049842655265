import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import moment from "moment-timezone";
import React, { useState } from "react";
import { timezones as tzs } from "../constants/timezone";

const TimezoneSelector = ({
  timezone,
  setTimezone,
}: {
  timezone: string;
  setTimezone: (timezone: string) => void;
}) => {
  const timezones: string[] = moment.tz.names();
  const [query, setQuery] = useState("");
  const filteredTimezones = timezones
    .filter((timezone) => timezone.toLowerCase().includes(query.toLowerCase()))
    .filter((timezone) => tzs.includes(timezone));

  return (
    <Listbox
      value={timezone}
      onChange={setTimezone}
    >
      {({ open }) => (
        <div>
          <label className="flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900">
            Timezone
          </label>
          <div
            className={classNames(
              "relative h-auto transition-all ease-in duration-100",
              {
                "h-[25rem]": open,
              }
            )}
          >
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
              <span className="block truncate">
                {timezone} (GMT{moment.tz(timezone).format("Z")})
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm no-scrollbar">
                <input
                  placeholder="Search..."
                  className="sticky top-0 rounded-t w-full bg-white z-50 border border-gray-200 shadow-md text-sm text-gray-700 mb-1 outline-none focus:outline-none"
                  value={query}
                  onChange={(e) => {
                    e.stopPropagation();
                    setQuery(e.target.value);
                  }}
                />
                {filteredTimezones.map((timezone) => (
                  <ListboxOption
                    key={timezone}
                    className={({ focus }) =>
                      classNames(
                        focus ? "bg-indigo-600 text-white" : "",
                        !focus ? "text-gray-900" : "",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={timezone}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {timezone} (GMT{moment.tz(timezone).format("Z")})
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              focus ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default TimezoneSelector;
