import { API_BASE_URL } from "../config";

export const V1 = "/api/v1";
export const BASE_URL = `${API_BASE_URL}${V1}`;
export const GENERATE_PRESIGNED_URL = "/utils/generate-presigned-url";
export const STRIPE_CREATE_CUSTOMER_PORTAL_SESSION =
  "/utils/create-customer-portal-session";
export const STRIPE_CREATE_CUSTOMER_SESSION = "/utils/create-customer-session";

// AUTH
const AUTH = `/auth`;
export const LOGIN = `${AUTH}/login`;
export const REGISTER = `${AUTH}/signup`;
export const VERIFY_EMAIL = `${AUTH}/verify-email`;
export const RESEND_VERIFY_EMAIL = `${AUTH}/resend-verification`;
export const PASSWORD_RECOVER = `${AUTH}/password-recovery/:email`;
export const PASSWORD_RESET = `${AUTH}/password-reset`;

// USER
export const USER = "/users/me";
export const CHANGE_USER_PASSWORD = `${USER}/password`;

// ACCOUNT
export const ACCOUNTS = "/accounts/:account_id";
export const LEAVE_ACCOUNT = `${ACCOUNTS}/leave`;
export const GET_ACCOUNT_USERS = `${ACCOUNTS}/users`;

// DASHBOARD
export const ARTICLE_DASHBOARD = `${ACCOUNTS}/dashboard`;

// ARTICLES
export const ARTICLES = `${ACCOUNTS}/articles`;
export const ARTICLES_BY_ID = `${ARTICLES}/:article_id`;

// ARTICLE TEMPLATE
export const TEMPLATES = `${ACCOUNTS}/templates`;
export const TEMPLATE_BY_ID = `${TEMPLATES}/:template_id`;

// ACCOUNT SUBSCRIPTION
export const ACTIVE_SUBSCRIPTION = `${ACCOUNTS}/subscriptions/active`;
export const SUBSCRIPTION = `${ACCOUNTS}/subscriptions`;

// DESTINATIONS
export const DESTINATIONS = `${ACCOUNTS}/destinations`;
export const DESTINATION_BY_ID = `${DESTINATIONS}/:destination_id`;
export const DESTINATION_TEST = `${ACCOUNTS}/destination-connection-test`;
export const DESTINATION_ATTACH_TO_TEMPLATE = `${DESTINATION_BY_ID}/templates/:template_id`;
