import classNames from "classnames";
import React from "react";

const TemplateContainer = ({
  children,
  className,
  style,
}: {
  children: any;
  className?: string;
  style?: any;
}) => {
  return (
    <div
      className={classNames(
        "w-full border-subtle rounded-lg border py-6 px-4 sm:px-6",
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default TemplateContainer;
