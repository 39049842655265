import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";
import { generateRandomNumber } from "../utils";
import ErrorMessage from "./ErrorMessage";
import LoadingSpinner from "./Spinner/LoadingSpinner";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  errorMessage?: string;
  errorPosition?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  loading?: boolean;
  className?: string;
}

const Search = ({
  id,
  label,
  errorMessage,
  errorPosition = "top-right",
  loading,
  className,
  ...rest
}: InputProps) => {
  const inputName = rest.name ?? `name-${generateRandomNumber(3)}`;
  return (
    <div
      className={classNames("w-full", className)}
      style={{ width: rest.width }}
    >
      <div className="sm:col-span-3">
        {label && (
          <label
            htmlFor={inputName}
            className="mb-1 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        )}
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            {...rest}
            id={id ?? inputName}
            name={inputName}
            autoComplete={inputName}
            className={classNames(
              "rounded-md block w-full border-0 py-1.5 p-4 ps-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
              {
                "ring-red-500": errorMessage,
              }
            )}
          />
          {loading && (
            <div className="absolute inset-y-0 end-3 flex items-center ps-3 pointer-events-none cursor-pointer">
              <LoadingSpinner />
            </div>
          )}
        </div>
        {errorMessage && errorPosition === "bottom-left" && (
          <ErrorMessage
            errorMessage={errorMessage}
            name={inputName}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
