import classNames from "classnames";
import React from "react";

export const MetricCard = ({ icon, iconBackground, heading, data }: any) => {
  return (
    <div className="overflow-hidden rounded-lg border">
      <div className="p-5 flex items-center">
        <div
          className={classNames("flex-shrink-0 p-2 rounded", iconBackground)}
        >
          {icon}
        </div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="truncate text-xl font-bold text-black">{data}</dt>
            <dd>
              <div className="text-gray-500 text-sm mt-0.5">{heading}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
