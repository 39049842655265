import { CheckIcon } from "@heroicons/react/20/solid";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "../../../components/Button";

const ArticleCopy = ({
  mediaUrl,
  articleId,
}: {
  mediaUrl: string;
  articleId: string;
}) => {
  const [copySuccessId, setCopySuccessId] = useState<{
    [key: string]: boolean;
  }>({});
  return (
    <Button
      id="copy-link"
      variant="icon"
      tooltip="Copy link"
      onClick={async () => {
        try {
          await navigator.clipboard.writeText(mediaUrl);
          setCopySuccessId({ [articleId]: true });
          setTimeout(() => {
            setCopySuccessId({ [articleId]: false });
          }, 500);
        } catch (err) {
          setCopySuccessId({ [articleId]: false });
        }
      }}
    >
      {Boolean(copySuccessId[articleId!]) ? (
        <CheckIcon className="size-4 text-green-600" />
      ) : (
        <Square2StackIcon className="size-4" />
      )}
    </Button>
  );
};

export default ArticleCopy;
