import { Popover, Transition } from "@headlessui/react";
import {
  ArchiveBoxXMarkIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { ROUTE_TEMPLATES_EDIT } from "../../../constants/routes";
import { TemplateStatusEnum } from "../../../enums/template";
import { useUpdateTemplateByIdMutation } from "../../../redux/services/templates";
import { getCurrentDateTimeFormatted } from "../../../utils";

const TemplateActions = ({
  templateId,
  status,
  isAuto,
  allowRerun,
}: {
  templateId: string;
  status: TemplateStatusEnum;
  isAuto: boolean;
  allowRerun: boolean;
}) => {
  const navigate = useNavigate();
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id || null;
  const [updateTemplateById, { isLoading }] = useUpdateTemplateByIdMutation();
  const handleArchive = async () => {
    await updateTemplateById({
      template_id: templateId,
      account_id: accountId,
      archived_at: getCurrentDateTimeFormatted(),
      template_status_id: TemplateStatusEnum.ARCHIVED,
    })
      .unwrap()
      .catch((error: any) => {
        console.log(error);
      });
  };

  let buttonText = "Rerun";
  if (isAuto || status === TemplateStatusEnum.DRAFT) {
    buttonText = "Edit";
  }
  return (
    <div className="flex gap-2 items-center justify-end">
      {allowRerun && (
        <>
          <Button
            variant="light-primary"
            paddingZero
            className="flex items-center gap-1 md:uppercase md:text-xs p-1"
            onClick={(e: any) => {
              e.stopPropagation();
              navigate(
                generatePath(ROUTE_TEMPLATES_EDIT, {
                  id: templateId,
                })
              );
            }}
          >
            <PencilSquareIcon className="size-4 md:hidden" /> {buttonText}
          </Button>
          <div className="h-4 w-px bg-gray-200" />
        </>
      )}
      {/* Popover for Archive Confirmation */}
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "text-white" : "text-white/90"}
                group inline-flex items-center p-0.5 outline-none`}
            >
              <Button
                variant="light-primary"
                paddingZero
                className="flex gap-1 items-center md:uppercase p-1"
                onClick={() => {}}
              >
                <ArchiveBoxXMarkIcon className="size-4" />
                <span className="md:hidden">Archive</span>
              </Button>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute xs:left-0 xs:bottom-0 md:right-0 md:top-5 z-10 w-max">
                <div className="overflow-hidden rounded shadow-lg ring-1 ring-blue/5">
                  <div className="relative flex flex-col gap-1 bg-blue-50 px-4 py-2">
                    <div className="text-sm text-gray-700 mb-2 font-semibold">
                      Archive this template?
                    </div>
                    <div className="flex gap-3">
                      <Button
                        variant="primary"
                        className="min-w-10"
                        paddingZero
                        loading={isLoading}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleArchive();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="transparent"
                        paddingZero
                        onClick={(e: any) => {
                          e.stopPropagation();
                          close();
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      {/*  */}
    </div>
  );
};

export default TemplateActions;
