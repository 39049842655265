import React from "react";
import RefreshButton from "./RefreshButton";

const PageHeading = ({
  heading,
  loading = false,
  tooltip,
  refetch,
}: {
  heading: string;
  loading?: boolean;
  tooltip?: string;
  refetch?: Function;
}) => {
  return (
    <div className="flex items-center gap-1">
      <div className="text-lg md:text-xl">{heading}</div>
      {refetch && <RefreshButton {...{ tooltip, refetch, loading }} />}
    </div>
  );
};

export default PageHeading;
