import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import Button from "../../../components/Button";

interface Props {
  onRequestClose: () => void;
}

const WordpressAppPassGuide = ({ onRequestClose }: Props) => {
  return (
    <div className="transition-all ease-linear duration-300 no-scrollbar h-max overflow-y-scroll border border-gray-50 rounded-lg bg-gray-100">
      <div className="relative transition-all ease-in-out p-4 text-gray-700">
        <Button
          variant="icon"
          onClick={onRequestClose}
          className="absolute top-2 right-2"
        >
          <XMarkIcon className="w-4" />
        </Button>
        <div className="font-medium text-sm">
          Setup a WordPress Application Password
        </div>
        <div className="flex flex-col my-2 gap-1 text-sm">
          <div>1. Go to your WordPress dashboard.</div>
          <div>2. Navigate to your user profile.</div>
          <div className="ml-3">
            a. Scroll down to "Application Passwords," name it (e.g.,
            “ArticleX”), and generate it.
          </div>
          <div className="ml-3">
            b. Copy the password and paste it into the ArticleX "Application
            Password" field.
          </div>
          <div>3. Make sure the connection fields are filled out.</div>
          <div>4. Click "Test" to test the connection from ArticleX.</div>
          <div>5. Click “Save”</div>
        </div>
      </div>
    </div>
  );
};

export default WordpressAppPassGuide;
