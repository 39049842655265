import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import Modal from "../Modal";

const TokenLimitReached = ({
  onRequestClose,
}: {
  onRequestClose: () => void;
}) => {
  return (
    <Modal
      className="p-8"
      width={550}
      onRequestClose={onRequestClose}
      isOpen={true}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center w-14 h-14 rounded-full bg-red-50">
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-red-100">
              <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
            </div>
          </div>
          <div className="text-xl font-semibold text-red-600">
            Token Limit Reached
          </div>
        </div>
        <div className="text-gray-700">
          There are not enough tokens remaining to generate further articles.
        </div>
      </div>
    </Modal>
  );
};

export default TokenLimitReached;
