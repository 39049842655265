import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React from "react";

// Header
export const Header = ({
  header,
  className,
}: {
  header: string;
  className?: string;
}) => (
  <th
    scope="col"
    className={classNames(
      "uppercase tracking-wide text-xs bg-gray-50 py-3 text-gray-500 pl-8 border-b rounded-t-lg",
      className
    )}
  >
    {header}
  </th>
);

// Body
export const Data = ({
  data,
  className,
}: {
  data: any;
  className?: string;
}) => {
  return (
    <td
      className={classNames(
        "py-2 pl-4 pr-3 text-sm text-gray-600 sm:pl-6 lg:pl-8 border-b",
        className
      )}
    >
      {data}
    </td>
  );
};

// Pagination
interface PaginationProps {
  offset: number;
  limit: number;
  total: number;
  setOffset: (offset: number) => void;
  style?: any;
}

export const Pagination = ({
  offset,
  limit,
  total,
  setOffset,
  style,
}: PaginationProps) => {
  const onPrev = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const onNext = () => {
    if (offset + limit < total) {
      setOffset(offset + limit);
    }
  };

  return (
    <div className="w-full">
      <div
        style={style}
        className="text-gray-500 text-sm border rounded-lg divide-gray-300 divide-x flex gap-6 ml-auto w-max m-2 p-2 items-center"
      >
        <div>
          <span className="font-semibold">
            {`${offset + 1}-${Math.min(offset + limit, total)}`}
          </span>{" "}
          <span>{`of ${total}`}</span>
        </div>
        <div className="flex gap-4">
          <ChevronLeftIcon
            className={classNames("size-5 ml-3 cursor-pointer", {
              "text-gray-300 cursor-not-allowed": offset === 0,
            })}
            onClick={onPrev}
          />
          <ChevronRightIcon
            className={classNames("size-5 cursor-pointer", {
              "text-gray-300 cursor-not-allowed": offset + limit >= total,
            })}
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};
