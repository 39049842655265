import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Tooltip } from "react-tooltip";

const InfoTooltip = ({
  id,
  tooltip,
  children,
}: {
  id: string;
  tooltip?: string;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <Tooltip
        id={id}
        place="right"
        delayShow={100}
        style={{
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: "1.25rem",
          maxWidth: "30rem",
          zIndex: 9999,
          textTransform: "none",
          background: "#000",
        }}
      >
        {tooltip || children}
      </Tooltip>
      <InformationCircleIcon
        data-tooltip-id={id}
        data-tooltip-content={tooltip}
        className="size-5 hover:fill-gray-200"
      />
    </>
  );
};

export default InfoTooltip;
