import React from "react";
import { Template } from "../../../models";
import AdditionalSettings from "./AdditionalSettings";
import BrandSettings from "./BrandSettings";
import EmbedMedia from "./EmbedMedia";
import Faq from "./FAQ";
import ImageSettings from "./ImageSettings";

type AdvancedSettingsProps = {
  values: Template;
  setFieldValue: any;
};

const AdvancedSettings = ({ values, setFieldValue }: AdvancedSettingsProps) => {
  return (
    <div className="animate-fadeIn space-y-6">
      <ImageSettings
        values={values}
        setFieldValue={setFieldValue}
      />
      <EmbedMedia
        values={values}
        setFieldValue={setFieldValue}
      />
      <Faq
        values={values}
        setFieldValue={setFieldValue}
      />
      <BrandSettings
        values={values}
        setFieldValue={setFieldValue}
      />
      <AdditionalSettings
        values={values}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default AdvancedSettings;
