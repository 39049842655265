import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Logo from "../../components/Logo";
import Message from "../../components/Message";
import { Heading, Text } from "../../components/Typography";
import { ROUTE_LOGIN } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { useForgotPasswordMutation } from "../../redux/services/auth";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [message, setMessage] = useState("");
  const [resetError, setResetError] = useState("");

  const handleForgotPassword = async (email: string) => {
    setResetError("");
    setMessage("");
    await forgotPassword(email)
      .unwrap()
      .then((response) => {
        if (response?.status_code === 200 && response?.message) {
          let message: string = response?.message as string;
          if (message.toLowerCase() === "password recovery email sent.") {
            message +=
              " If you don't see the email confirmation within 5 minutes, please check your spam.";
          }
          setMessage(message);
        } else {
          setResetError("Something went wrong. Please contact support.");
        }
      })
      .catch((error) => {
        if (error?.status === 404) {
          setResetError(error?.data?.detail);
        } else {
          setResetError("Something went wrong. Please contact support.");
        }
      });
  };
  const handleLogin = () => navigate(ROUTE_LOGIN);

  return (
    <SpringContainer centered>
      <Formik
        initialValues={initialValues}
        onSubmit={({ email }) => handleForgotPassword(email)}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={loginSchema}
      >
        {(props) => {
          const { values, errors, setFieldValue } = props;
          return (
            <div className="mx-auto w-full text-center space-y-6">
              <Logo />
              <Heading size="xl">Forgot Password</Heading>
              <Text className="text-sm text-gray-500">
                Enter the email associated with your ArticleX account
              </Text>
              <Form className="space-y-6 max-w-sm mx-auto">
                <Message
                  type={message ? "success" : "error"}
                  message={message || resetError}
                  className="justify-center"
                />
                <Input
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  errorMessage={errors?.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
                <Button
                  fullWidth
                  variant="primary"
                  type="submit"
                  onClick={() => {}}
                  loading={isLoading}
                >
                  Reset Password
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
      <div className="mt-10 text-center text-sm text-gray-500">
        Go back to{" "}
        <Button
          paddingZero
          variant="transparent-primary"
          onClick={handleLogin}
        >
          Sign In
        </Button>
      </div>
    </SpringContainer>
  );
};

export default ForgotPassword;
