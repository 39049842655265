import * as d3 from "d3";
import React, { useCallback, useEffect, useRef } from "react";
import { formatDateUS } from "../utils";

interface BarChartProps {
  data: { date: string; tokens_used: number; article_count: number }[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const chartRef = useRef<SVGSVGElement | null>(null);

  const renderChart = useCallback(() => {
    if (!chartRef.current || !data) return;

    const isMediumScreen = window.innerWidth < 1280;

    // Clear previous chart
    d3.select(chartRef.current).selectAll("*").remove();

    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const svg = d3.select(chartRef.current);
    const width = svg.node()?.parentElement?.clientWidth ?? 1400;
    const height = 400;

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const chart = svg
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const dateStrings = data.map((d) => formatDateUS(d.date, false));

    // Set up scales
    const xScale = d3
      .scaleBand()
      .domain(dateStrings)
      .range([10, innerWidth]) // Added 10px padding on the left
      .padding(0.4);

    const maxTokensUsed = d3.max(data, (d) => d.tokens_used) || 0;
    const yDomain = maxTokensUsed === 0 ? [0, 1] : [0, maxTokensUsed];
    const yScale = d3.scaleLinear().domain(yDomain).range([innerHeight, 0]);

    // Add bars
    chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(formatDateUS(d?.date?.toString(), false)) ?? 0)
      .attr("y", (d) => yScale(d.tokens_used))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => innerHeight - yScale(d.tokens_used))
      .attr("fill", "rgb(49, 196, 141)")
      .attr("rx", 5)
      .attr("ry", 5);

    // Add x-axis
    chart
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .call((g) => g.select(".domain").attr("stroke", "#6b7280"))
      .call(
        (g) =>
          g
            .selectAll(".tick text")
            .attr("fill", "#6b7280") // Set the tick label color
            .style("text-anchor", isMediumScreen ? "end" : "middle") // Center text within band
            .attr("dx", isMediumScreen ? "-0.8em" : "0em") // Center text within band
            .attr("dy", isMediumScreen ? "0.15em" : "1em") // Adjust vertical position to be below the bar
            .attr("transform", isMediumScreen ? "rotate(-90)" : "rotate(0)") // Rotate text
      )
      .call((g) => g.selectAll(".tick line").attr("stroke", "#6b7280")); // Set the tick mark color

    // Add y-axis
    chart
      .append("g")
      .call(d3.axisLeft(yScale))
      .call((g) => g.select(".domain").attr("stroke", "#6b7280"))
      .call(
        (g) =>
          g.selectAll(".tick text").attr("fill", "#6b7280").attr("dx", "-.5em") // Set the tick label color
      )
      .call((g) => g.selectAll(".tick line").attr("stroke", "#6b7280")) // Set the tick mark color
      .call((g) =>
        g
          .append("text")
          .attr("x", -margin.left + 9)
          .attr("y", -20)
          .attr("fill", "#6b7280")
          .attr("text-anchor", "start")
          .text("↑ Tokens")
      );
  }, [data]);

  useEffect(() => {
    renderChart();
    window.addEventListener("resize", renderChart);

    return () => window.removeEventListener("resize", renderChart);
  }, [data, renderChart]);

  return <svg ref={chartRef}></svg>;
};

export default BarChart;
