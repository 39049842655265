import React, { useState } from "react";
import Button from "../../components/Button";
import DeleteProfileModal from "./DeleteProfileModal";

const DangerZone = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  return (
    <>
      <div className="w-full border-subtle rounded-lg border">
        <div className="px-6 py-4">
          <div className="text-danger font-semibold">Danger zone</div>
          <div className="text-gray-500 text-sm">
            Be Careful. This action is permanent and unrecoverable.
          </div>
        </div>
        <div className="border-t bg-gray-50 flex items-center justify-start px-6 py-4 rounded-b-lg">
          <Button
            variant="danger"
            onClick={() => setDeleteModal(true)}
          >
            Delete Account
          </Button>
        </div>
      </div>
      {deleteModal && (
        <DeleteProfileModal onRequestClose={() => setDeleteModal(false)} />
      )}
    </>
  );
};

export default DangerZone;
