import { DestinationTypeEnum } from "../enums/destination";

export class Destination {
  destination_id?: string;
  destination_type_id: DestinationTypeEnum;
  name: string;
  destination: {
    url: string;
    username: string;
    author_id?: number | null;
    category_id?: number | null;
    secret_key: string;
    status: string;
  };
  created_at?: string;
  template_id_list?: string[];
  template_list?: {
    template_id: string;
    template_name: string;
  }[];

  constructor({
    destination_id,
    destination_type_id,
    name,
    destination,
    created_at,
    template_id_list,
    template_list,
  }: any) {
    this.destination_id = destination_id;
    this.destination_type_id = destination_type_id;
    this.name = name;
    this.destination = {
      url: destination.url,
      username: destination.username,
      author_id: destination.author_id,
      category_id: destination.category_id,
      secret_key: destination.secret_key,
      status: destination.status,
    };
    this.created_at = created_at;
    this.template_id_list = template_id_list;
    this.template_list = template_list;
  }
}
