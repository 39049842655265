import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account, User } from "../../models";

interface UserState {
  user: User | null;
  selectedAccount: Account | null;
  showUpgradeModal: boolean;
}

const initialState: UserState = {
  user: null,
  selectedAccount: null,
  showUpgradeModal: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = new User(action.payload);
    },
    setSelectedAccount: (state, action: PayloadAction<Account | null>) => {
      state.selectedAccount = action.payload;
    },
    setUpgradeModal: (state, action: PayloadAction<boolean>) => {
      state.showUpgradeModal = action.payload;
    },
  },
});

export const { setUser, setSelectedAccount, setUpgradeModal } =
  userSlice.actions;
export default userSlice.reducer;
