import { generatePath } from "react-router-dom";
import { ACCOUNTS, GET_ACCOUNT_USERS, LEAVE_ACCOUNT } from "../apiEndPoints";
import { api } from "./api";

export const accountApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountById: builder.query({
      query: (account_id: string) => ({
        url: generatePath(ACCOUNTS, { account_id }),
        method: "GET",
      }),
      providesTags: (result, error, account_id) => [
        { type: "Account", account_id },
      ],
    }),
    updateAccountById: builder.mutation({
      query: ({ account_id, ...rest }) => ({
        url: generatePath(ACCOUNTS, { account_id }),
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, { account_id }) => [
        { type: "Account", id: account_id },
        { type: "User" },
      ],
    }),
    deleteAccountById: builder.mutation({
      query: (account_id: string) => ({
        url: generatePath(ACCOUNTS, { account_id }),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, account_id) => [
        { type: "Account", id: account_id },
      ],
    }),
    getAccountUsersById: builder.query({
      query: (account_id: string) => ({
        url: generatePath(GET_ACCOUNT_USERS, { account_id }),
        method: "GET",
      }),
      providesTags: (result, error, account_id) => [
        { type: "AccountUsers", id: account_id },
      ],
    }),
    leaveAccountById: builder.mutation({
      query: (account_id: string) => ({
        url: generatePath(LEAVE_ACCOUNT, { account_id }),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, account_id) => [
        { type: "Account", id: account_id },
        { type: "AccountUsers", id: account_id },
      ],
    }),
  }),
});

export const {
  useGetAccountByIdQuery,
  useUpdateAccountByIdMutation,
  useDeleteAccountByIdMutation,
  useGetAccountUsersByIdQuery,
  useLeaveAccountByIdMutation,
} = accountApiSlice;
