import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../../components/Spinner/LoadingSpinner";

interface Props {
  articleUrl: string;
  className?: string;
}

const ArticleHTML = ({ articleUrl, className }: Props) => {
  const renderedRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHtmlFile = async () => {
      try {
        const response = await fetch(articleUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch HTML file");
        }
        let html = await response.text();

        // Fetch iframe content and embed it into the main document
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const iframes = doc.querySelectorAll("iframe");

        for (const iframe of iframes) {
          try {
            const iframeDoc =
              iframe.contentDocument || iframe.contentWindow?.document;
            if (iframeDoc) {
              iframe.innerHTML = iframeDoc.body.innerHTML; // Inject iframe content into the main document
            }
          } catch (e) {
            console.error("Error accessing iframe content: ", e);
          }
        }

        html = doc.documentElement.innerHTML;
        setHtmlContent(html);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching HTML file:", error);
        setLoading(false);
      }
    };

    if (articleUrl) {
      fetchHtmlFile();
    }
  }, [articleUrl]);

  useEffect(() => {
    // Ensure the Instagram embed script is loaded and executed
    const script = document.createElement("script");
    script.src = "https://www.instagram.com/embed.js";
    script.async = true;
    script.onload = () => {
      if ((window as any).instgrm && htmlContent) {
        (window as any).instgrm.Embeds.process();
      }
    };

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, [htmlContent]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60rem]">
        <LoadingSpinner size="32px" />
      </div>
    );
  }

  return (
    <div
      ref={renderedRef}
      id="html-rendered"
      dangerouslySetInnerHTML={{
        __html: htmlContent
          .replace(
            /<ul>/g,
            '<ul style="list-style-type: disc; margin-left: 20px;">'
          )
          .replace(
            /<ol>/g,
            '<ol style="list-style-type: decimal; margin-left: 20px;">'
          ),
      }}
      // style={{ fontFamily: "Times" }}
    />
  );
};

export default ArticleHTML;
