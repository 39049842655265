import classNames from "classnames";
import React from "react";
import { Heading } from "../../../components/Typography";
import { TemplateStepEnum, TemplateSteps } from "../TemplateCreate";
import TemplateSideNavItem from "./common/TemplateSideNavItem";

const SideNav = ({ step, setStep, validateForm }: any) => {
  const handleTabClick = (step: TemplateStepEnum) => {
    validateForm().then((formErrors: any) => {
      if (Object.keys(formErrors).length === 0) {
        setStep(step);
      }
    });
  };
  return (
    <>
      <div className="hidden xl:block">
        <nav
          className="no-scrollbar flex flex-col gap-2 overflow-scroll primary-navigation sticky top-0 -mt-7"
          aria-label="Tabs"
        >
          <Heading className="mb-2">Create Article Template</Heading>
          {TemplateSteps.map((props) => (
            <TemplateSideNavItem
              key={props.name}
              current={props.name === step}
              onClick={(e) => {
                e.stopPropagation();
                handleTabClick(props.name);
              }}
              {...props}
            />
          ))}
        </nav>
      </div>
      <div className="p-2 md:mx-0 md:p-0 xl:hidden">
        <div className="mb-4 h-9 max-w-full lg:mb-5">
          <Heading className="mb-4">Create Article Template</Heading>
          <nav
            className="no-scrollbar flex gap-2 overflow-x-scroll rounded-md"
            aria-label="Tabs"
          >
            {TemplateSteps.map((props) => {
              const current = props?.name === step;
              return (
                <button
                  type="button"
                  id={props?.name}
                  key={props?.name}
                  className={classNames(
                    "border p-2 hover:border-transparent hover:bg-gray-100 text-sm text-gray-600 rounded",
                    {
                      "border-transparent bg-gray-100 font-semibold": current,
                    },
                    {
                      "border-transparent": !current,
                    }
                  )}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleTabClick(props.name);
                  }}
                >
                  <div className="flex items-center justify-center whitespace-nowrap transition-all ease-linear">
                    {props.icon as any}
                    {props.title}
                  </div>
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </>
  );
};

export default SideNav;
