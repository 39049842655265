import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { ROUTE_TEMPLATES_CREATE } from "../../constants/routes";
import { setUpgradeModal } from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useGetDashboardQuery } from "../../redux/services/dashboard";
import { browserTimeZone } from "../../utils";
import { InstagramSVG } from "../TemplateCreate/icons";

const ArticleCreateHero = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id || null;
  const { data } = useGetDashboardQuery(
    {
      account_id: accountId || "",
      timezone: selectedAccount?.timezone || browserTimeZone,
    },
    { skip: !accountId }
  );
  return (
    <div className="max-w-full">
      <div
        data-testid="empty-screen"
        className="flex w-full select-none flex-col items-center justify-center rounded-lg p-7 lg:p-20 border-subtle border border-dashed mb-16"
      >
        <div className="flex items-center gap-4">
          <div className="bg-gray-50 flex h-[72px] w-[72px] items-center justify-center rounded-full">
            {InstagramSVG}
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
              />
            </svg>
          </div>
          <div className="bg-gray-50 flex h-[72px] w-[72px] items-center justify-center rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="lucide lucide-link text-default inline-block h-10 w-10 stroke-[1.3px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
              />
            </svg>
          </div>
        </div>
        <div className="flex max-w-[420px] flex-col items-center">
          <h2 className="font-cal text-primaryText text-center text-xl mt-6">
            Create your first article.
          </h2>
          <div className="text-default mb-8 mt-3 text-center text-sm font-normal leading-6">
            Discover the magic of turning your Instagram Reels into captivating
            articles! Just provide the link to your Reel, and watch as we craft
            a unique and engaging article that captures the essence of your
            content.
          </div>
          <Button
            variant="primary"
            onClick={() => {
              if (data?.tokens_remaining < 1) {
                dispatch(setUpgradeModal(true));
              } else {
                navigate(ROUTE_TEMPLATES_CREATE);
              }
            }}
            className="px-3"
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ArticleCreateHero;
