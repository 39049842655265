import React, { useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useMedia } from "react-use";
import {
  ROUTE_BILLING,
  ROUTE_PLANS,
  ROUTE_RENEW_PLANS,
  ROUTE_ROOT,
} from "../../constants/routes";
import useSubscription from "../../hooks/useSubscription";
import Banner from "../Banner";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

const FULLSCREEN_ROUTES = [ROUTE_PLANS, ROUTE_RENEW_PLANS];
const message =
  "Your plan has expired. Please renew to continue generating articles.";
const linkRoute = ROUTE_BILLING;

const Layout = ({ children }: { children: React.ReactElement }) => {
  const { subscription } = useSubscription();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const isMobileOrTablet = useMedia("(max-width: 1280px)");

  // Toggle sidebar state based on screen size
  useLayoutEffect(() => {
    setSidebarOpen(!isMobileOrTablet);
  }, [isMobileOrTablet]);

  const { pathname } = useLocation();
  const isFullscreenRoute = useMemo(
    () => FULLSCREEN_ROUTES.includes(pathname ?? ""),
    [pathname]
  );

  const showPlanExpiredWarning = useMemo(
    () => subscription?.status === "canceled" && pathname === ROUTE_ROOT,
    [subscription, pathname]
  );

  if (isFullscreenRoute) {
    return <div className="h-full">{children}</div>;
  }

  return (
    <div className="flex flex-col h-full w-full">
      <Header {...{ sidebarOpen, setSidebarOpen }} />
      <div className="flex flex-1 min-w-0">
        <Sidebar {...{ sidebarOpen }} />
        <main className="flex-1 h-full overflow-auto">
          {showPlanExpiredWarning && <Banner {...{ message, linkRoute }} />}
          <div className="h-full">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
