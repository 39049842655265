import { api } from "./api";
import { USER, CHANGE_USER_PASSWORD } from "../apiEndPoints";
import { User } from "../../models";

export const userApiSlice: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => USER,
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<Partial<User>, void>({
      query: (data) => ({
        url: USER,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: USER,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    changePassword: builder.mutation<
      { current_password: string; new_password: string },
      void
    >({
      query: (data) => ({
        url: CHANGE_USER_PASSWORD,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useChangePasswordMutation,
} = userApiSlice;
