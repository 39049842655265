import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import gmailEmail from "../../assets/images/email-gmail.png";
import outLookEmail from "../../assets/images/email-outlook.png";
import yahooEmail from "../../assets/images/email-yahoo.png";
import Button from "../../components/Button";
import Logo from "../../components/Logo";
import Message from "../../components/Message";
import { Text } from "../../components/Typography";
import { ROUTE_LOGIN } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { useResendVerificationEmailMutation } from "../../redux/services/auth";

const SuccessfulSignUp = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const email = encodeURIComponent(params.get("email") ?? "");
  const [resendVerificationEmail] = useResendVerificationEmailMutation();
  const [resendError, setResendError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (!email) {
      navigate(ROUTE_LOGIN);
    }
  }, [email, navigate]);

  const handleResendEmail = async (email: string) => {
    setResendError("");
    setSuccessMessage("");
    await resendVerificationEmail(email)
      .unwrap()
      .then((response) => {
        if (response?.status_code === 200 && response?.message) {
          setSuccessMessage(response.message);
        } else {
          setResendError("Something went wrong. Please contact support.");
        }
      })
      .catch(() => {
        setResendError("Something went wrong. Please contact support.");
      });
  };

  return (
    <SpringContainer centered>
      <div className="mx-auto w-full max-w-sm md:max-w-lg text-center">
        <Logo />
        <div className="my-6 text-center">
          <p className="text-gray-500">
            A verification link has been sent to{" "}
            <b>{decodeURIComponent(email)}</b>.
          </p>
          <p className="text-gray-500">
            Please check your email.{" "}
            <span className="text-amber-600">
              If you don't see the email confirmation within 5 minutes, please
              check your spam.
            </span>
          </p>

          <Message
            type={successMessage ? "success" : "error"}
            message={successMessage || resendError}
            className="justify-center"
          />
        </div>
      </div>
      <div className="flex gap-1 justify-center md:gap-4 max-w-lg mx-auto">
        <EmailButton
          href="https://mail.google.com/"
          src={gmailEmail}
        >
          Open Gmail
        </EmailButton>
        <EmailButton
          href="https://outlook.office365.com/mail/"
          src={outLookEmail}
        >
          Open Outlook
        </EmailButton>
        <EmailButton
          href="https://mail.yahoo.com/"
          src={yahooEmail}
        >
          Open Yahoo
        </EmailButton>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 w-full mt-10">
        <Text
          size="sm"
          align="center"
          className="text-gray-500"
        >
          Do you want to{" "}
          <Button
            paddingZero
            variant="transparent-primary"
            onClick={() => handleResendEmail(email)}
          >
            Resend Email
          </Button>
        </Text>
        <Text
          size="sm"
          align="center"
          className="text-gray-500"
        >
          Go back to{" "}
          <Button
            paddingZero
            variant="transparent-primary"
            onClick={() => navigate(ROUTE_LOGIN)}
          >
            Login
          </Button>
        </Text>
      </div>
    </SpringContainer>
  );
};

const EmailButton = ({
  children,
  src,
  href,
}: React.ComponentProps<"button"> & { src?: string; href: string }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="border border-gray-200 border-solid rounded-md py-3 px-3 md:px-6 bg-white hover:bg-gray-100 flex items-center outline-none focus:outline-none">
        <img
          style={{ width: 20 }}
          src={src}
          alt="Email Provider"
        />
        <div className="text-sm ml-1">{children}</div>
      </button>
    </a>
  );
};

export default SuccessfulSignUp;
