import { useSelector } from "react-redux";
import { browserTimeZone } from "../utils";

const useSelectedAccount = () => {
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id || null;
  const timezone = selectedAccount?.timezone || browserTimeZone;

  return { selectedAccount, accountId, timezone };
};

export default useSelectedAccount;
