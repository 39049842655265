import { generatePath } from "react-router-dom";
import { ARTICLES, ARTICLES_BY_ID } from "../apiEndPoints";
import { api } from "./api";

export const articleApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllArticles: builder.query({
      query: ({ account_id, offset, limit, include_total_count, search }) => {
        let url =
          generatePath(ARTICLES, { account_id }) +
          `?offset=${offset}&limit=${limit}&include_total_count=${include_total_count}`;
        if (search) {
          url += `&search=${search}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: ["Articles"],
    }),
    getArticleById: builder.query({
      query: ({ article_id, account_id }) => ({
        url: generatePath(ARTICLES_BY_ID, { account_id, article_id }),
        method: "GET",
      }),
    }),
    deleteArticleById: builder.mutation({
      query: ({ article_id, account_id }) => ({
        url: generatePath(ARTICLES_BY_ID, { account_id, article_id }),
        method: "DELETE",
      }),
      invalidatesTags: ["Articles"],
    }),
  }),
});

export const {
  useGetAllArticlesQuery,
  useLazyGetAllArticlesQuery,
  useGetArticleByIdQuery,
  useDeleteArticleByIdMutation,
} = articleApiSlice;
