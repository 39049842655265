import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import CheckSVG from "../../../assets/svg/check.svg";
import InstagramSVG from "../../../assets/svg/instagram.svg";
import WordpressSVG from "../../../assets/svg/wordpress.svg";
import Button from "../../../components/Button";
import {
  ROUTE_ARTICLES,
  ROUTE_DESTINATIONS_EDIT,
  ROUTE_TEMPLATES_EDIT,
} from "../../../constants/routes";
import {
  BrandPersonaEnum,
  BrandPersonaMap,
  BrandToneEnum,
  BrandToneMap,
  BrandVoiceEnum,
  BrandVoiceMap,
  TemplateStatusEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import { getSourceSVG, sortNumbers } from "../../../utils";
import IsAutoEnabled from "./IsAutoEnabled";
import TemplateActions from "./TemplateActions";
import TemplateStatus from "./TemplateStatus";

export const headers = [
  { key: "name", label: "Title" },
  { key: "brand", label: "Brand Persona" }, //Brand (Persona / Voice / Tone)
  { key: "is_auto_enabled", label: "Auto" },
  { key: "include_image", label: "Image" },
  { key: "include_faq", label: "FAQ" },
  { key: "article_count", label: "Articles" },
  { key: "destination_id_list", label: "Destination" },
  { key: "template_status_id", label: "Status" },
  { key: "template_id", label: "" },
];

const TemplateTable = ({ templates }: { templates: Template[] }) => {
  const navigate = useNavigate();
  const linkToArticles = useCallback(
    (templateName: string) => {
      navigate(ROUTE_ARTICLES, {
        state: { templateName },
      });
    },
    [navigate]
  );

  const renderTemplateData = useCallback(
    (key: string, template: any) => {
      switch (key) {
        case "name":
          return (
            <div className="flex items-center gap-3">
              <img
                alt="source"
                src={getSourceSVG(template["source_id"])}
                className="size-5"
              />
              <div className="truncate overflow-hidden">{template["name"]}</div>
            </div>
          );
        case "brand":
          return (
            <div className="truncate overflow-hidden">
              {template["brand_persona_id"] &&
                BrandPersonaMap[
                  template["brand_persona_id"] as BrandPersonaEnum
                ]}
            </div>
          );
        case "include_image":
          return (
            <div className="flex justify-center">
              {template["include_image"] ? (
                <img
                  alt="include_image"
                  src={CheckSVG}
                  className="size-4"
                />
              ) : (
                <XMarkIcon className="size-4 text-red-600" />
              )}
            </div>
          );
        case "include_faq":
          return (
            <div className="flex justify-center">
              {template["include_faq"] ? (
                <img
                  alt="include_faq"
                  src={CheckSVG}
                  className="size-4"
                />
              ) : (
                <XMarkIcon className="size-4 text-red-600" />
              )}
            </div>
          );
        case "is_auto_enabled":
          return (
            <div className="flex justify-center">
              <IsAutoEnabled
                isAutoEnabled={template["is_auto_enabled"]}
                autoDays={sortNumbers(template["auto_days"] || [])}
                autoTime1={template["auto_time1"]}
                autoTime2={template["auto_time2"]}
                timezone={template["timezone"]}
              />
            </div>
          );
        case "template_status_id":
          return (
            <div className="w-max flex gap-2 items-center">
              <TemplateStatus
                status={template["template_status_id"]}
                linkToArticles={() => linkToArticles(template["name"])}
              />
            </div>
          );
        case "template_id":
          return (
            <div>
              <TemplateActions
                templateId={template["template_id"]}
                isAuto={template["is_auto_enabled"]}
                status={template["template_status_id"]}
                allowRerun={
                  template["template_status_id"] !== TemplateStatusEnum.QUEUED
                }
              />
            </div>
          );
        case "article_count":
          if (template["article_count"] > 0) {
            return (
              <div className="text-center">
                <Button
                  paddingZero
                  variant="light-primary"
                  className="p-1"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    linkToArticles(template["name"]);
                  }}
                >
                  {template["article_count"]}
                </Button>
              </div>
            );
          } else {
            return (
              <div className="text-center">{template["article_count"]}</div>
            );
          }
        case "destination_id_list":
          if (!template["destination_id_list"]) {
            return null;
          }
          return (
            <div className="flex items-center justify-center">
              <Button
                variant="icon"
                paddingZero
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(
                    generatePath(ROUTE_DESTINATIONS_EDIT, {
                      id: template?.["destination_id_list"]?.[0],
                    }),
                    "_blank"
                  );
                }}
              >
                <img
                  alt="wordpress-logo"
                  src={WordpressSVG}
                  className="size-5"
                />
              </Button>
            </div>
          );

        default:
          return <div>{template[key]}</div>;
      }
    },
    [linkToArticles]
  );

  return (
    <>
      <table className="hidden md:block mt-6 min-w-full border-separate border-spacing-0 border-r border-l border-t">
        <thead>
          <tr>
            {headers.map(({ key, label, sub }: any) => {
              let width = "7%";
              let padding = "0.75rem";
              if (key === "name") {
                width = "40%";
              } else if (key === "is_auto_enabled") {
                width = "10%";
              }

              return (
                <th
                  key={key}
                  scope="col"
                  className={classNames(
                    "uppercase tracking-wide text-xs bg-gray-50 py-3 text-gray-500 border-b rounded-t-lg",
                    {
                      "text-center": [
                        "include_image",
                        "include_faq",
                        "is_auto_enabled",
                        "destination_id_list",
                      ].includes(key),
                    },
                    {
                      "text-start": ![
                        "include_image",
                        "include_faq",
                        "is_auto_enabled",
                        "destination_id_list",
                      ].includes(key),
                    }
                  )}
                  style={{ width: width, padding: padding }}
                >
                  {label}
                  <div>{sub}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {templates
            .filter(
              ({ template_status_id }) =>
                template_status_id !== TemplateStatusEnum.ARCHIVED
            )
            .map((template: Template, index: number) => (
              <tr
                key={template.name + index}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  navigate(
                    generatePath(ROUTE_TEMPLATES_EDIT, {
                      id: template["template_id"],
                    })
                  );
                }}
              >
                {headers.map(({ key }: any) => {
                  let maxWidth = "200px";
                  return (
                    <td
                      key={key}
                      className="text-sm text-gray-600 border-b py-2 px-4"
                      style={{ maxWidth }}
                    >
                      {renderTemplateData(key, template)}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
      {/* Cards */}
      <div className="md:hidden flex flex-col gap-6">
        {templates.map((template: Template, index: number) => {
          return (
            <div
              key={index}
              className="border rounded shadow p-4 flex flex-col gap-4"
            >
              <div className="flex items-center gap-3">
                <img
                  alt="source"
                  src={InstagramSVG}
                  className="size-5"
                />
                <div className="truncate overflow-hidden">
                  {template["name"]}
                </div>
              </div>

              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Brand (Persona / Voice / Tone)
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {template["brand_persona_id"]
                    ? BrandPersonaMap[
                        template["brand_persona_id"] as BrandPersonaEnum
                      ]
                    : "-"}
                  {" / "}
                  {template["brand_voice_id"]
                    ? BrandVoiceMap[
                        template["brand_voice_id"] as BrandVoiceEnum
                      ]
                    : "-"}
                  {" / "}
                  {template["brand_tone_id"]
                    ? BrandToneMap[template["brand_tone_id"] as BrandToneEnum]
                    : "-"}
                </div>
              </div>

              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Include Image
                </div>
                <div>
                  {template["include_image"] ? (
                    <img
                      alt="include_image"
                      src={CheckSVG}
                      className="size-4"
                    />
                  ) : (
                    <XMarkIcon className="size-4 text-red-600" />
                  )}
                </div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Include FAQ
                </div>
                <div>
                  {template["include_faq"] ? (
                    <img
                      alt="include_faq"
                      src={CheckSVG}
                      className="size-4"
                    />
                  ) : (
                    <XMarkIcon className="size-4 text-red-600" />
                  )}
                </div>
              </div>

              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Auto
                </div>
                <div className="text-sm">
                  <IsAutoEnabled
                    isAutoEnabled={template["is_auto_enabled"]}
                    autoDays={sortNumbers(template["auto_days"]! || [])}
                    autoTime1={template["auto_time1"]!}
                    autoTime2={template["auto_time2"]!}
                    timezone={template["timezone"]}
                  />
                </div>
              </div>

              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Status
                </div>
                <div className="w-max flex gap-2 items-center text-sm">
                  <TemplateStatus
                    status={template["template_status_id"]}
                    linkToArticles={() => linkToArticles(template["name"])}
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="uppercase text-xs font-semibold text-gray-500">
                    Article Count
                  </div>
                  {template["article_count"] > 0 ? (
                    <Button
                      variant="light-primary"
                      className="min-w-16 text-start p-1"
                      paddingZero
                      onClick={(e: any) => {
                        e.stopPropagation();
                        navigate(ROUTE_ARTICLES, {
                          state: { templateName: template["name"] },
                        });
                      }}
                    >
                      {template["article_count"]}
                    </Button>
                  ) : (
                    <div>{template["article_count"]}</div>
                  )}
                </div>
              </div>
              <div>
                <div className="h-px w-full bg-gray-200" />
                <div className="mt-4 flex gap-4 items-center">
                  <TemplateActions
                    templateId={template["template_id"]}
                    status={template["template_status_id"]}
                    isAuto={template["is_auto_enabled"]}
                    allowRerun={
                      template["template_status_id"] !==
                      TemplateStatusEnum.QUEUED
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TemplateTable;
