import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import Search from "../../components/Search";
import { ROUTE_ARTICLES } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { useGetAllArticlesQuery } from "../../redux/services/article";
import ArticleCreateHero from "../ArticleCreateHero";
import ArticleTableContainer from "./components/ArticleTableContainer";

export const limit = 20;
const Articles = () => {
  const navigate = useNavigate();
  const templateName = useLocation()?.state?.templateName;
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = React.useState("");

  const [totalArticles, setTotalArticles] = useState(0);
  const [searchTotalArticle, setSearchTotalArticle] = useState(0);
  const [offset, setOffset] = useState(0);
  const accountId = selectedAccount?.account_id || null;
  const { data, isLoading, refetch, isFetching, isUninitialized } =
    useGetAllArticlesQuery(
      {
        account_id: accountId || "",
        offset: offset,
        limit: limit,
        search: debouncedSearch || "",
        include_total_count: true,
      },
      { skip: !accountId }
    );

  useEffect(() => {
    if (accountId) {
      refetch();
    }
  }, [accountId, offset, refetch]);

  useEffect(() => {
    // Redirect from template screen
    if (templateName) {
      setSearch(templateName);
      setDebouncedSearch(templateName);
    }
  }, [templateName]);

  useEffect(() => {
    if (!totalArticles && data?.total_count !== undefined) {
      setTotalArticles(data.total_count);
    }
  }, [data?.total_count, totalArticles, setTotalArticles]);

  useEffect(() => {
    if (debouncedSearch && data?.total_count !== undefined) {
      setSearchTotalArticle(data.total_count);
    } else {
      setSearchTotalArticle(0);
    }
  }, [data?.total_count, debouncedSearch, setSearchTotalArticle]);

  const articles = data?.data || [];
  const articlesExists = articles && articles?.length > 0;

  if (isUninitialized || isLoading) {
    return null;
  }

  return (
    <SpringContainer>
      <div className="space-y-4">
        <div className="flex gap-2 items-center">
          <PageHeading
            heading="Articles"
            tooltip="Refresh"
            loading={isFetching}
            refetch={refetch}
          />
          <Search
            value={search}
            placeholder="Search by title or template"
            onChange={(e) => {
              setSearch(e?.target?.value);
              // TODO: check for inbuilt functionality of navigate to reset state
              if (templateName && !e?.target?.value) {
                navigate(ROUTE_ARTICLES, { state: { templateName: "" } });
              }
            }}
            onBlur={() => setDebouncedSearch(search)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                setDebouncedSearch(search);
              }
            }}
            loading={isFetching}
            width={300}
          />
        </div>

        {!articlesExists && !debouncedSearch && <ArticleCreateHero />}
        {!articlesExists && debouncedSearch && (
          <div className="text-sm text-gray-700">No Article found</div>
        )}
        {articlesExists && (
          <ArticleTableContainer
            articles={articles}
            showPagination={
              debouncedSearch
                ? searchTotalArticle > limit
                : totalArticles > limit
            }
            totalArticles={debouncedSearch ? searchTotalArticle : totalArticles}
            offset={offset}
            setOffset={setOffset}
          />
        )}
      </div>
    </SpringContainer>
  );
};

export default Articles;
