import React from "react";
const ErrorMessage = ({
  errorMessage,
  name,
}: {
  errorMessage: string;
  name: string;
}) => (
  <span
    className="uppercase inline-flex text-2xs tracking-wide font-medium leading-6 text-red-500"
    id={`${name}-error`}
  >
    {errorMessage}
  </span>
);

export default ErrorMessage;
