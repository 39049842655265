import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Tooltip } from "react-tooltip";
import Spinner from "../../../components/Spinner/GeneratingSpinner";
import {
  ArticleStatusColorMap,
  ArticleStatusEnum,
  ArticleStatusMap,
} from "../../../models";

const ArticleStatus = ({ article_status_id, generate_error }: any) => {
  return (
    <div className="flex gap-1 items-center text-sm">
      {article_status_id !== ArticleStatusEnum.ERROR && (
        <div className="flex gap-1 items-center md:text-sm">
          {article_status_id === ArticleStatusEnum.GENERATING && (
            <div className="w-3.5 h-3.5">
              <Spinner />
            </div>
          )}
          {article_status_id !== ArticleStatusEnum.GENERATING && (
            <div
              className="w-2 h-2 rounded-full"
              style={{
                background:
                  ArticleStatusColorMap[article_status_id as ArticleStatusEnum],
              }}
            />
          )}
          {ArticleStatusMap[article_status_id as ArticleStatusEnum]}
        </div>
      )}
      {article_status_id === ArticleStatusEnum.ERROR && (
        <div
          className="flex items-center gap-1 text-sm"
          data-tooltip-id="article-generate-error"
          data-tooltip-content={generate_error}
        >
          <Tooltip
            id="article-generate-error"
            place="top"
          />
          <ExclamationTriangleIcon className="w-4 h-4 text-red-500" />
          {ArticleStatusMap[article_status_id as ArticleStatusEnum]}
        </div>
      )}
    </div>
  );
};

export default ArticleStatus;
