import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { ROUTE_TEMPLATES_EDIT } from "../../../constants/routes";
import { Article } from "../../../models";
import {
  formatDateUS,
  formatNumberWithCommas,
  getSourceSVG,
} from "../../../utils";
import ArticleActions from "./ArticleActions";

import ArticleCopy from "./ArticleCopy";
import ArticleStatus from "./ArticleStatus";

const headers = [
  { key: "title", label: "Title" }, // 40%
  { key: "media_url", label: "Source" }, // 7%
  { key: "template_name", label: "Template" }, // 17%
  { key: "article_word_count", label: "Words" }, // 7%
  { key: "tokens_used", label: "Tokens used" }, // 10%
  { key: "article_status_id", label: "Status" }, // 10%
  { key: "created_at", label: "Created" }, // 13%
  { key: "s3_article_url", label: "" }, // 4%
];

// ArticleTable component
const ArticleTable = ({
  articles,
  handleArticleAction,
}: {
  articles: Article[];
  handleArticleAction: (
    id: string,
    name: string,
    url: string,
    action: "" | "view" | "download"
  ) => void;
}) => {
  const navigate = useNavigate();

  const renderArticleData = useCallback(
    (key: string, article: any) => {
      switch (key) {
        case "title":
          return (
            <div
              className={classNames("flex items-center gap-3", {
                "text-red-500": article["generate_error"],
              })}
            >
              <img
                alt="source"
                src={getSourceSVG(article["source_id"])}
                className="size-5"
              />
              <div className="truncate overflow-hidden">{article[key]}</div>
            </div>
          );
        case "template_name":
          return (
            <div
              role="button"
              tabIndex={0}
              className="truncate overflow-hidden text-primary"
              onClick={() => {
                navigate(
                  generatePath(ROUTE_TEMPLATES_EDIT, {
                    id: article["template_id"],
                  })
                );
              }}
            >
              {article[key]}
            </div>
          );
        case "created_at":
          return (
            <div className="truncate overflow-hidden">
              {formatDateUS(article["created_at"])}
            </div>
          );
        case "tokens_used":
          return (
            <div>{formatNumberWithCommas(article["tokens_used"]) || "-"}</div>
          );
        case "s3_article_url":
          if (!article["s3_article_url"]) {
            return null;
          }
          return (
            <ArticleActions
              articleId={article["article_id"]}
              articleUrl={article["s3_article_url"]}
              onView={() =>
                handleArticleAction(
                  article["article_id"],
                  article["title"],
                  article["s3_article_url"],
                  "view"
                )
              }
            />
          );
        case "media_url":
          return (
            <div className="flex justify-end">
              <a
                href={article["media_url"]}
                target="_blank"
                rel="noreferrer"
                className="group"
              >
                <Button
                  id="open-link"
                  tooltip="Open link in a new tab"
                  variant="icon"
                  onClick={() => {}}
                >
                  <ArrowTopRightOnSquareIcon className="size-4 " />
                </Button>
              </a>
              <ArticleCopy
                mediaUrl={article["media_url"]}
                articleId={article["article_id"]}
              />
            </div>
          );
        case "article_word_count":
          return <div>{formatNumberWithCommas(article[key]) || "-"}</div>;
        case "article_status_id":
          return (
            <div className="flex justify-end">
              <ArticleStatus
                article_status_id={article["article_status_id"]}
                generate_error={article["generate_error"]}
              />
            </div>
          );
        default:
          return <div>{article[key] || "-"}</div>;
      }
    },
    [handleArticleAction, navigate]
  );
  return (
    <>
      <table className="hidden md:block min-w-full border-separate border-spacing-0 border-r border-l border-t overflow-x-auto">
        <thead>
          <tr>
            {headers.map(({ key, label }: any) => {
              let width = "10%";
              let padding = "0.75rem";
              if (key === "title") {
                width = "40%";
                padding = "0.75rem 1rem";
              } else if (key === "media_url") {
                width = "6%";
              } else if (key === "template_name") {
                width = "18%";
              } else if (key === "s3_article_url") {
                width = "4%";
              } else if (key === "article_word_count") {
                width = "7%";
              } else if (key === "created_at") {
                width = "13%";
              }

              return (
                <th
                  key={key}
                  scope="col"
                  className={classNames(
                    "uppercase tracking-wide text-xs bg-gray-50 text-gray-500 border-b rounded-t-lg truncate overflow-hidden",
                    {
                      "text-end": [
                        "media_url",
                        "template_name",
                        "article_word_count",
                        "tokens_used",
                        "article_status_id",
                        "created_at",
                      ].includes(key),
                    },
                    "text-start"
                  )}
                  style={{ width: width, padding: padding }}
                >
                  {label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {articles.map((article: Article, index: number) => (
            <tr key={article["article_id"]}>
              {headers.map(({ key }: any) => {
                let width = "10%";
                let padding = "0.75rem";
                let maxWidth = "100px";
                if (key === "title") {
                  width = "40%";
                  padding = "0.75rem 1rem";
                } else if (key === "template_name") {
                  width = "18%";
                } else if (key === "media_url") {
                  width = "6%";
                  padding = "0 0.75rem";
                } else if (key === "s3_article_url") {
                  width = "4%";
                  padding = "0 0.75rem";
                } else if (key === "article_word_count") {
                  width = "7%";
                } else if (key === "created_at") {
                  width = "13%";
                }
                return (
                  <td
                    key={index + key}
                    className={classNames(
                      "text-sm text-gray-600 border-b truncate overflow-hidden",
                      {
                        "text-end": [
                          "media_url",
                          "template_name",
                          "article_word_count",
                          "tokens_used",
                          "article_status_id",
                          "created_at",
                          "s3_article_url",
                        ].includes(key),
                      },
                      "text-start"
                    )}
                    style={{ width, padding, maxWidth }}
                  >
                    {renderArticleData(key, article)}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Cards */}
      <div className="flex flex-col gap-6 md:hidden">
        {articles.map((article: Article, index: number) => {
          const {
            title,
            template_name,
            generate_error,
            created_at,
            article_word_count,
            article_status_id,
            tokens_used,
          } = article;
          return (
            <div
              key={index}
              className="border rounded shadow p-4 flex flex-col gap-4"
            >
              <div
                className={classNames("flex items-center gap-3", {
                  "text-red-500": generate_error,
                })}
              >
                <img
                  alt="source"
                  src={getSourceSVG(article["source_id"])}
                  className="size-5"
                />
                <div className="truncate overflow-hidden">{title}</div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Template
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {template_name}
                </div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Status
                </div>
                <ArticleStatus
                  article_status_id={article_status_id}
                  generate_error={generate_error}
                />
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Word Count
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {formatNumberWithCommas(article_word_count) || "-"}
                </div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Tokens Used
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {formatNumberWithCommas(tokens_used) || "-"}
                </div>
              </div>
              <div>
                <div className="uppercase text-xs font-semibold text-gray-500">
                  Created At
                </div>
                <div className="truncate overflow-hidden text-sm">
                  {formatDateUS(created_at)}
                </div>
              </div>
              <div>
                <div className="h-px w-full bg-gray-200" />
                <div className="mt-4 flex gap-1 items-center">
                  <Button
                    variant="light-primary"
                    paddingZero
                    onClick={() => {
                      window.open(article["media_url"], "_blank");
                    }}
                    className="flex gap-1 items-center p-1"
                  >
                    <ArrowTopRightOnSquareIcon className="size-4" />
                    Source
                  </Button>
                  <div className="h-4 w-px bg-gray-200" />
                  <ArticleActions
                    articleId={article["article_id"]}
                    articleUrl={article["s3_article_url"]}
                    onView={() =>
                      handleArticleAction(
                        article["title"],
                        article["title"],
                        article["s3_article_url"],
                        "view"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(ArticleTable);
