import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import ArticleCopy from "../../Articles/components/ArticleCopy";

const DestinationSaveModal = ({
  message,
  loading,
  onRequestClose,
  onSave,
}: {
  message: string;
  loading: boolean;
  onRequestClose: () => void;
  onSave: () => void;
}) => {
  return (
    <Modal
      className="p-8"
      width={550}
      onRequestClose={onRequestClose}
      isOpen={true}
    >
      <div className="space-y-6 text-gray-800">
        {message && (
          <div className="space-y-2">
            <div className="flex items-center gap-1">
              <CheckCircleIcon className="size-5 text-green-600" /> Success
            </div>
            <div>
              A test article was posted as a draft at the following link.
            </div>
            <div className="flex items-center gap-1">
              <div className="px-2 bg-gray-100 rounded text-center w-[29rem] truncate">
                <code className="text-xs">{message.split("Link: ")?.[1]}</code>
              </div>
              <ArticleCopy
                mediaUrl={message.split("Link: ")?.[1]}
                articleId="link"
              />
            </div>
          </div>
        )}
        <div className="flex items-center gap-2">
          <Button
            variant="primary"
            loading={loading}
            onClick={onSave}
          >
            Save Connection
          </Button>
          <Button
            variant="default"
            onClick={onRequestClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DestinationSaveModal;
