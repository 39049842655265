import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";

interface BannerProps {
  message?: string;
  linkRoute?: string;
  type?: "warning" | "success" | "info";
}

export default function Banner({
  message = "",
  linkRoute = "",
  type = "warning",
}: BannerProps) {
  const navigate = useNavigate();

  const getBannerStyles = () => {
    switch (type) {
      case "success":
        return "from-green-500 via-green-600 to-green-500 hover:bg-green-600";
      case "info":
        return "from-blue-500 via-blue-600 to-blue-500 hover:bg-blue-600";
      case "warning":
      default:
        return "from-red-500 via-red-600 to-red-500 hover:bg-red-600";
    }
  };

  const getIcon = () => {
    switch (type) {
      case "success":
        return (
          <CheckCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-white"
          />
        );
      case "info":
        return (
          <InformationCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-white"
          />
        );
      case "warning":
      default:
        return (
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-5 w-5 text-white"
          />
        );
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => navigate(linkRoute)}
      className={`flex justify-center items-center px-6 py-2.5 shadow bg-gradient-to-r cursor-pointer ${getBannerStyles()}`}
    >
      <div className="flex items-center gap-2 text-base leading-6 text-white">
        {getIcon()}
        <span className="text-sm font-medium">{message}</span>
        <span aria-hidden="true">&rarr;</span>
      </div>
    </div>
  );
}
