import { useSelector } from "react-redux";
import { useGetSubscriptionQuery } from "../redux/services/subscription";

const useSubscription = () => {
  // Get the selected account from the Redux state
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id || null;

  // Fetch subscription data only if an accountId exists
  const {
    data: subscription,
    isLoading,
    isUninitialized,
    isError,
  } = useGetSubscriptionQuery(
    { account_id: accountId },
    { skip: !accountId } // Skip query if accountId is null
  );

  return {
    accountId,
    subscription,
    isLoading,
    isUninitialized,
    isError,
  };
};

export default useSubscription;
