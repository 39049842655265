import React from "react";
import { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import {
  ROUTE_ARTICLES,
  ROUTE_ARTICLE_CREATE,
  ROUTE_ARTICLE_DOWNLOAD,
  ROUTE_BILLING,
  ROUTE_DESTINATIONS,
  ROUTE_DESTINATIONS_CREATE,
  ROUTE_DESTINATIONS_EDIT,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_PLANS,
  ROUTE_PROFILE,
  ROUTE_RENEW_PLANS,
  ROUTE_RESET_PASSWORD,
  ROUTE_ROOT,
  ROUTE_SIGN_UP_EMAIL,
  ROUTE_SUCCESSFUL_SIGN_UP,
  ROUTE_TEMPLATES,
  ROUTE_TEMPLATES_CREATE,
  ROUTE_TEMPLATES_EDIT,
  ROUTE_VERIFY_EMAIL,
} from "../../constants/routes";
import Articles from "../../pages/Articles";
import ForgotPassword from "../../pages/ForgotPassword";
import Login from "../../pages/Login";
import Profile from "../../pages/Profile";
import Signup from "../../pages/SignUpEmail";
import SuccessfulSignUp from "../../pages/SuccessfulSignUp";
import Templates from "../../pages/Templates";
import VerifyEmail from "../../pages/VerifyEmail";
import App from "../App";
import ProtectedRoute from "./components/ProtectedRoute";

import { useSelector } from "react-redux";
import TokenLimitReached from "../../components/modals/TokenLimitReached";
import "../../index.css";
import ArticleCreateContainer from "../../pages/ArticleCreateContainer";
import ArticlePDFDownload from "../../pages/Articles/components/ArticlePDFDownload";
import Billing from "../../pages/Billing";
import Dashboard from "../../pages/Dashboard";
import Destinations from "../../pages/Destinations";
import DestinationConnect from "../../pages/Destinations/DestinationConnect";
import Plans from "../../pages/Plans";
import RenewPlans from "../../pages/Plans/RenewPlans";
import ResetPassword from "../../pages/ResetPassword";
import TemplateCreate from "../../pages/TemplateCreate/TemplateCreate";
import { setUpgradeModal } from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/hooks";

Modal.setAppElement("#root");
const routes = {
  protected: [
    { path: ROUTE_ROOT, element: <Dashboard /> },
    { path: ROUTE_PLANS, element: <Plans /> },
    { path: ROUTE_RENEW_PLANS, element: <RenewPlans /> },
    { path: ROUTE_ARTICLES, element: <Articles /> },
    { path: ROUTE_ARTICLE_CREATE, element: <ArticleCreateContainer /> },
    { path: ROUTE_TEMPLATES, element: <Templates /> },
    { path: ROUTE_TEMPLATES_CREATE, element: <TemplateCreate /> },
    { path: ROUTE_TEMPLATES_EDIT, element: <TemplateCreate /> },
    { path: ROUTE_DESTINATIONS_EDIT, element: <DestinationConnect /> },
    { path: ROUTE_DESTINATIONS_CREATE, element: <DestinationConnect /> },
    { path: ROUTE_DESTINATIONS, element: <Destinations /> },
    { path: ROUTE_PROFILE, element: <Profile /> },
    { path: ROUTE_BILLING, element: <Billing /> },
  ],
  public: [
    { path: ROUTE_LOGIN, element: <Login /> },
    { path: ROUTE_SIGN_UP_EMAIL, element: <Signup /> },
    { path: ROUTE_SUCCESSFUL_SIGN_UP, element: <SuccessfulSignUp /> },
    { path: ROUTE_VERIFY_EMAIL, element: <VerifyEmail /> },
    { path: ROUTE_FORGOT_PASSWORD, element: <ForgotPassword /> },
    { path: ROUTE_RESET_PASSWORD, element: <ResetPassword /> },
    {
      path: ROUTE_ARTICLE_DOWNLOAD,
      element: <ArticlePDFDownload />,
    },
  ],
};

const Container = () => {
  const dispatch = useAppDispatch();
  const showUpgradeModal = useSelector(
    (state: any) => state.user.showUpgradeModal
  );
  return (
    <div className="h-full">
      <Router>
        <Routes>
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            {routes.protected.map(({ path, element }, index) => (
              <Route
                key={index}
                path={path}
                element={element}
              />
            ))}
          </Route>
          {/* Public Routes */}
          <Route element={<App />}>
            {routes.public.map(({ path, element }, index) => (
              <Route
                key={index}
                path={path}
                element={element}
              />
            ))}
          </Route>
        </Routes>
      </Router>

      {showUpgradeModal && (
        <TokenLimitReached
          onRequestClose={() => dispatch(setUpgradeModal(false))}
        />
      )}
      <Toaster />
    </div>
  );
};

export default Container;
