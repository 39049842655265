import { FormikErrors } from "formik";
import React from "react";
import InfoTooltip from "../../../components/InfoTooltip";
import RadioGroup, { Option } from "../../../components/RadioGroup";
import { FaqQuestionPrefixEnum, NoOfFaqEnum } from "../../../enums/template";
import { Template } from "../../../models";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

const faqPrefixOptions = [
  {
    id: FaqQuestionPrefixEnum.Q_LETTER,
    name: "Q",
    description: "Ex: Q: Your article question?",
  },
  {
    id: FaqQuestionPrefixEnum.Q_MARK,
    name: "?",
    description: "Ex: ?: Your article question?",
  },
  {
    id: null,
    name: "None",
    description: "Ex: Your article question?",
  },
];

const noOffaqOptions = [
  {
    id: NoOfFaqEnum.TWO,
    name: "2",
  },
  {
    id: NoOfFaqEnum.THREE,
    name: "3",
  },
  {
    id: NoOfFaqEnum.FOUR,
    name: "4",
  },
  {
    id: NoOfFaqEnum.FIVE,
    name: "5",
  },
  {
    id: NoOfFaqEnum.SIX,
    name: "6",
  },
];

type FAQProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

const Faq = ({ values, setFieldValue }: FAQProps) => {
  return (
    <div className="space-y-6 animate-fadeIn">
      <TemplateContainer>
        <div>
          <SwitchContainer
            title="Include FAQs"
            tooltip='Add a "Frequently Asked Questions" section to the bottom of the article.'
            enabled={Boolean(values?.include_faq)}
            setEnabled={() => {
              setFieldValue("include_faq", !values?.include_faq);
              if (!values?.include_faq && !values.faq_question_prefix_id) {
                setFieldValue(
                  "faq_question_prefix_id",
                  FaqQuestionPrefixEnum.Q_LETTER
                );
              }
              if (!values?.include_faq && !values.faq_count) {
                setFieldValue("faq_count", NoOfFaqEnum.THREE);
              }
            }}
          />
        </div>
        {Boolean(values?.include_faq) && (
          <>
            <div className="my-6">
              <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
                Select a question format
                <InfoTooltip
                  id="question_format"
                  tooltip='For aesthetics, you can add a prefix of "Q:" or "?:" to the questions of the FAQ.'
                />
              </h3>
              <RadioGroup
                options={faqPrefixOptions}
                name="faqPrefix"
                selected={faqPrefixOptions.find(
                  ({ id }) => id === values?.faq_question_prefix_id
                )}
                onClick={({ id }: Option) =>
                  setFieldValue("faq_question_prefix_id", id)
                }
              />
            </div>
            <div className="w-max">
              <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
                How many FAQs to add?
              </h3>
              <RadioGroup
                options={noOffaqOptions}
                name="faq_count"
                selected={noOffaqOptions.find(
                  ({ id }) => id === values?.faq_count
                )}
                onClick={({ id }: Option) => setFieldValue("faq_count", id)}
              />
            </div>
          </>
        )}
      </TemplateContainer>
    </div>
  );
};

export default Faq;
