import { CheckCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

interface Conditions {
  hasValidLength: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasSymbol: boolean;
  hasNumber: boolean;
}

const PasswordValidator = ({ password }: { password: string }) => {
  const [passwordConditions, setPasswordConditions] = useState<Conditions>({
    hasValidLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSymbol: false,
    hasNumber: false,
  });

  useEffect(() => {
    setPasswordConditions({
      hasValidLength: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasSymbol: /[\W_]/.test(password),
      hasNumber: /\d/.test(password),
    });
  }, [password]);

  const { hasValidLength, hasSymbol, hasNumber, hasUppercase, hasLowercase } =
    passwordConditions;

  const conditionConfig = [
    {
      key: "valid_length",
      label: "8 Chars",
      value: hasValidLength,
    },
    {
      key: "has_uppercase",
      label: "1 Upper",
      value: hasUppercase,
    },
    {
      key: "has_lowercase",
      label: "1 Lower",
      value: hasLowercase,
    },
    {
      key: "has_symbol",
      label: "1 Symbol",
      value: hasSymbol,
    },
    {
      key: "has_number",
      label: "1 Num",
      value: hasNumber,
    },
  ];

  return (
    <div className="mt-2 flex gap-3 text-3xs font-semibold uppercase tracking-wider text-gray-400">
      {conditionConfig.map((condition) => (
        <span
          key={condition.key}
          className={classNames("flex items-center gap-1", {
            "text-green-600": condition.value,
          })}
        >
          <CheckCircleIcon className="size-4" /> {condition.label}
        </span>
      ))}
    </div>
  );
};

export default PasswordValidator;
