import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { useGetArticleByIdQuery } from "../../../redux/services/article";

const ArticlePDFDownload = () => {
  const renderedRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true);
  let { id: article_id } = useParams();
  const account_id = useSearchParam("accountId");
  const { data: article } = useGetArticleByIdQuery(
    { article_id, account_id },
    { skip: !account_id }
  );

  const articleUrl = article?.s3_article_url || null;

  useEffect(() => {
    const fetchHtmlFile = async () => {
      try {
        const response = await fetch(articleUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch HTML file");
        }
        let html = await response.text();

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Remove all iframes from the document
        const iframes = doc.querySelectorAll("iframe");

        iframes.forEach((iframe) => {
          const parent = iframe.parentElement;
          if (parent) {
            parent.remove();
          } else {
            iframe.remove();
          }
        });

        // Remove all blockquote elements with the class "instagram-media"
        const blockquotes = doc.querySelectorAll("blockquote.instagram-media");
        blockquotes.forEach((blockquote) => {
          const parentDiv = blockquote.closest("div")?.closest("div");
          if (parentDiv) {
            parentDiv.remove();
          } else {
            blockquote.remove();
          }
        });

        // Remove hr tag
        const hrTags = doc.querySelectorAll("hr");
        hrTags.forEach((hr) => {
          hr.remove();
        });

        // Get the modified HTML
        html = doc.documentElement.innerHTML;
        setHtmlContent(html);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching HTML file:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (articleUrl) {
      fetchHtmlFile();
    }
  }, [articleUrl]);

  useEffect(() => {
    if (htmlContent) {
      setTimeout(() => window.print(), 1500);
      window.onafterprint = function () {
        window.close();
      };
    }
  }, [htmlContent]);

  if (loading) {
    return null;
  }

  return (
    <div
      ref={renderedRef}
      id="html-rendered"
      dangerouslySetInnerHTML={{
        __html: htmlContent
          .replace(
            /<ul>/g,
            '<ul style="list-style-type: disc; margin-left: 20px;">'
          )
          .replace(
            /<ol>/g,
            '<ol style="list-style-type: decimal; margin-left: 20px;">'
          ),
      }}
      // style={{ fontFamily: "Times" }}
    />
  );
};

export default ArticlePDFDownload;
