import React from "react";
import Select, {
  OptionProps,
  SingleValue,
  StylesConfig,
  components,
} from "react-select";
import InfoTooltip from "./InfoTooltip";

export interface OptionType {
  label: string;
  value: any;
  description: string;
  icon?: any;
}

type SelectProps = {
  label: string;
  placeholder: string;
  options: OptionType[];
  selected?: SingleValue<OptionType>;
  setSelected: (option: SingleValue<OptionType>) => void;
  tooltip?: string;
  className?: string;
  isClearable?: boolean;
  maxMenuHeight?: number;
};

const CustomOption = (props: OptionProps<OptionType>) => {
  return (
    <components.Option {...props}>
      <div className="flex items-start md:items-center px-2">
        {props?.data?.icon && (
          <img
            alt="select-option-icon"
            src={props.data.icon}
            className="w-5 h-5 mr-2"
          />
        )}
        <div className="text-sm min-w-[8rem] md:min-w-[12rem]">
          {props.data.label}
        </div>
        <div className="text-sm text-gray-500">{props.data.description}</div>
      </div>
    </components.Option>
  );
};

const customStyles: StylesConfig<OptionType, false> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#e5e7eb"
      : state.isFocused
        ? "#f3f4f6"
        : "#fff",
    color: state.isSelected ? "#1f2937" : "#1f2937",
    padding: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#325BED" : "#d1d5db",
    boxShadow: state.isFocused ? "0 0 0 1px #325BED" : "none",
    marginTop: 0,
    borderRadius: 7,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1f2937",
    fontSize: "0.9rem",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

const CustomSelect = ({
  label,
  placeholder,
  options,
  selected,
  setSelected,
  tooltip,
  className,
  isClearable = true,
  maxMenuHeight = 300,
}: SelectProps) => {
  const handleChange = (option: any) => {
    setSelected(option);
  };

  return (
    <div className={className}>
      <label
        htmlFor={label.toLowerCase().replace(" ", "-")}
        className="mb-1 flex items-center gap-2 uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
      >
        {label}
        {tooltip && (
          <InfoTooltip
            id={label}
            tooltip={tooltip}
          />
        )}
      </label>
      <Select
        id={label.toLowerCase().replace(" ", "-")}
        placeholder={placeholder}
        value={selected}
        onChange={handleChange}
        options={options}
        components={{ Option: CustomOption }}
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={customStyles}
        isClearable={isClearable}
        maxMenuHeight={maxMenuHeight}
      />
    </div>
  );
};

export default CustomSelect;
