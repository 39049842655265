import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import { ROUTE_TEMPLATES_CREATE } from "../../constants/routes";
import { useGetAllArticlesQuery } from "../../redux/services/article";
import ArticleCreateHero from "../ArticleCreateHero";

const ArticleCreateContainer = () => {
  const navigate = useNavigate();
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id || null;

  const { data, isSuccess, isFetching } = useGetAllArticlesQuery(
    {
      account_id: accountId || "",
      offset: 0,
      limit: 1,
      include_total_count: true,
    },
    { skip: !accountId, refetchOnMountOrArgChange: true }
  );

  if (isFetching) {
    return <LoadingSpinner size="14px" />;
  }

  // Conditional redirect based on total_count
  if (isSuccess && data?.total_count > 0) {
    navigate(ROUTE_TEMPLATES_CREATE);
    return null; // Prevent rendering ArticleCreateHero after redirect
  }

  return <ArticleCreateHero />;
};

export default ArticleCreateContainer;
