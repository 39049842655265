import cn from "classnames";
import React from "react";

type BaseTextProps = {
  className?: string;
  width?: number | string;
  size?:
    | "5xs"
    | "4xs"
    | "3xs"
    | "2xs"
    | "xs"
    | "xs"
    | "sm"
    | "base"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "8xl";
  color?:
    | "primary"
    | "success"
    | "warning"
    | "danger"
    | "shark"
    | "shuttle-gray"
    | "gray"
    | "bombay"
    | "raven"
    | "mid-gray"
    | "white";
  leading?: "none" | "tight" | "snug" | "normal" | "relaxed" | "loose";
  tracking?: "xs" | "sm" | "base" | "md" | "lg" | "xl" | "2xl" | "3xl";
  weight?:
    | "hairline"
    | "thin"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";
  align?: "left" | "right" | "center" | "justify";
  uppercase?: boolean;
  children?: React.ReactNode;
};

type HeadingProps = BaseTextProps & {};

export const Heading: React.FC<HeadingProps> = ({
  size = "base",
  width,
  color,
  leading,
  tracking,
  weight,
  align,
  uppercase,
  className,
  children,
}) => {
  return (
    <div
      className={cn(
        `text-${size} font-semibold`,
        color ? `text-${color}` : undefined,
        leading ? `leading-${leading}` : undefined,
        tracking ? `tracking-${tracking}` : undefined,
        weight ? `font-${weight}` : undefined,
        align ? `text-${align}` : undefined,
        uppercase ? "uppercase" : undefined,
        className
      )}
      style={{ width }}
    >
      {children}
    </div>
  );
};

type TextProps = BaseTextProps & {};

export const Text: React.FC<TextProps> = ({
  size = "base",
  width = 600,
  color,
  leading,
  tracking,
  weight,
  align,
  uppercase,
  className,
  children,
}) => {
  return (
    <span
      className={cn(
        `text-${size}`,
        color ? `text-${color}` : undefined,
        leading ? `leading-${leading}` : undefined,
        tracking ? `tracking-${tracking}` : undefined,
        weight ? `font-${weight}` : undefined,
        align ? `text-${align}` : undefined,
        uppercase ? "uppercase" : undefined,
        className
      )}
      style={{ width }}
    >
      {children}
    </span>
  );
};
