import { Square2StackIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import LoadingSpinner from "../../../components/Spinner/LoadingSpinner";
import { copyAsRichText } from "../../../utils";
import ArticleHTML from "./ArticleHTML";

const ArticleViewModal = ({
  articleUrl,
  onRequestClose,
}: {
  articleUrl: string;
  onRequestClose: () => void;
}) => {
  const [copyStatus, setCopyStatus] = useState<"" | "copying" | "copied">("");
  const renderIcon = () => {
    switch (copyStatus) {
      case "copying":
        return <LoadingSpinner size="16px" />;
      case "copied":
        return <CheckIcon className="w-4 h-4 text-green-600" />;
      default:
        return <Square2StackIcon className="w-4 h-4" />;
    }
  };
  return (
    <Modal
      onRequestClose={onRequestClose}
      maxWidth={1280}
      width={1280}
      isOpen={true}
    >
      <div className="px-6 py-2 overflow-scroll no-scrollbar min-h-[60rem] mt-4">
        <div className="absolute top-4 right-12">
          <Button
            paddingZero
            variant={copyStatus === "" ? "transparent" : "disabled"}
            className="group h-auto p-2 min-h-6 min-w-6 text-sm text-gray-400 cursor-pointer bg-gray-100 hover:bg-gray-100 hover:text-gray-500 rounded-full flex items-center justify-center"
            onClick={async () => {
              if (copyStatus === "") {
                setCopyStatus("copying");
                copyAsRichText(articleUrl)
                  .then(() => {
                    setCopyStatus("copied");
                    setTimeout(() => setCopyStatus(""), 1000);
                  })
                  .catch(() => {
                    setCopyStatus("");
                  });
              }
            }}
          >
            <div className="flex items-center w-0 h-4 group-hover:w-[108px] transition-all ease-linear duration-200 overflow-hidden">
              <div className="hidden opacity-0 group-hover:flex group-hover:opacity-100 text-xs flex-nowrap whitespace-nowrap mx-1">
                Copy to clipboard
              </div>
            </div>
            {renderIcon()}
          </Button>
        </div>
        <ArticleHTML articleUrl={articleUrl} />
      </div>
    </Modal>
  );
};

export default ArticleViewModal;
