// Account Routes
export const ROUTE_ROOT = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_LOGOUT = "/logout";
export const ROUTE_SIGN_UP_EMAIL = "/signup";
export const ROUTE_SUCCESSFUL_SIGN_UP = "/successful-signup";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_VERIFY_EMAIL = "/verify";

// Articles
export const ROUTE_ARTICLES = "/articles";
export const ROUTE_ARTICLE_CREATE = "/articles/create";

// Article Settings
export const ROUTE_TEMPLATES = "/articles/templates";
export const ROUTE_TEMPLATES_CREATE = "/articles/templates/create";
export const ROUTE_TEMPLATES_EDIT = "/articles/templates/edit/:id";

// Destinations
export const ROUTE_DESTINATIONS = "/destinations";
export const ROUTE_DESTINATIONS_CREATE = "/destinations/create";
export const ROUTE_DESTINATIONS_EDIT = "/destinations/edit/:id";

// User Profile
export const ROUTE_PROFILE = "/profile";

// BILLING
export const ROUTE_BILLING = "/billing";
export const ROUTE_PLANS = "/plans";
export const ROUTE_RENEW_PLANS = "/renew-plans";

// ARTICLE PRINT
export const ROUTE_ARTICLE_DOWNLOAD = "/article-download/:id";
