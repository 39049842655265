import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Logo from "../../components/Logo";
import Message from "../../components/Message";
import { Heading } from "../../components/Typography";
import { ERROR_MESSAGE } from "../../constants/message";
import { regexEmailAdminLogin } from "../../constants/regex";
import {
  ROUTE_ARTICLE_CREATE,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_PLANS,
  ROUTE_ROOT,
  ROUTE_SIGN_UP_EMAIL,
} from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { useGetAllArticlesQuery } from "../../redux/services/article";
import { useLoginMutation } from "../../redux/services/auth";
import { useGetSubscriptionQuery } from "../../redux/services/subscription";
import { useGetUserQuery } from "../../redux/services/user";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .test("is-valid-email", "Invalid email address", (value) => {
      return value ? regexEmailAdminLogin.test(value) : false;
    }),
  password: Yup.string().required("Required"),
});

type loginFormValues = {
  email: string;
  password: string;
};

const initialValues: loginFormValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const { authToken } = useSelector((state: any) => ({
    authToken: state.auth.token,
  }));

  // Get User
  const { data: user } = useGetUserQuery({ skip: !authToken });
  const selectedAccount = user?.accounts?.[0] || "";
  const accountId = selectedAccount?.account_id || null;

  // Get Subscription
  const {
    data: subscription,
    isSuccess: subscriptionSuccess,
    isError: subscriptionError,
  } = useGetSubscriptionQuery(
    {
      account_id: accountId,
    },
    { skip: !selectedAccount }
  );

  // Get Articles
  const {
    data: articles,
    isSuccess: articleSuccess,
    isError: articleError,
  } = useGetAllArticlesQuery(
    {
      account_id: accountId || "",
      offset: 0,
      limit: 3,
      include_total_count: true,
    },
    { skip: !accountId }
  );

  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    if (!authToken) return;

    if (subscriptionSuccess) {
      if (!subscription) {
        navigate(ROUTE_PLANS);
      } else if (articleSuccess) {
        if (articles?.total_count > 0) {
          navigate(ROUTE_ROOT);
        } else {
          navigate(ROUTE_ARTICLE_CREATE);
        }
      }
    } else if (subscriptionError || articleError) {
      setLoginError("Something went wrong. Please contact support.");
    }
  }, [
    authToken,
    subscriptionSuccess,
    subscription,
    articleSuccess,
    articles,
    navigate,
    articleError,
    subscriptionError,
  ]);

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      setLoginError("");
      await login({
        username: email,
        password: password,
      })
        .unwrap()
        .then((response) => {
          if (!response?.access_token) {
            setLoginError(ERROR_MESSAGE);
          }
        })
        .catch((error) => {
          if (error?.status === 400 && error?.data?.detail) {
            setLoginError(error.data.detail);
          } else {
            setLoginError(ERROR_MESSAGE);
          }
        });
    },
    [login]
  );

  const navigateToForgotPassword = useCallback(
    () => navigate(ROUTE_FORGOT_PASSWORD),
    [navigate]
  );

  const navigateToSignUp = useCallback(
    () => navigate(ROUTE_SIGN_UP_EMAIL),
    [navigate]
  );

  return (
    <SpringContainer centered>
      <Formik
        initialValues={initialValues}
        onSubmit={({ email, password }) =>
          handleLogin(decodeURIComponent(email), password)
        }
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
        validationSchema={loginSchema}
      >
        {(props) => {
          const { values, errors, setFieldValue } = props;
          return (
            <div className="mx-auto w-full max-w-sm space-y-6 text-center">
              <Logo />
              <Heading size="xl">Sign in to your account</Heading>
              <Message
                type="error"
                message={loginError}
                className="justify-center"
              />
              <Form className="space-y-6">
                <Input
                  id="email"
                  label="Email"
                  name="email"
                  value={values.email}
                  errorMessage={errors?.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
                <div>
                  <Input
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={values.password}
                    errorMessage={errors?.password}
                    onChange={(e) => setFieldValue("password", e.target.value)}
                  />
                  <div className="mt-1 w-full text-right">
                    <Button
                      paddingZero
                      variant="transparent-primary"
                      onClick={navigateToForgotPassword}
                    >
                      Forgot password?
                    </Button>
                  </div>
                </div>
                <Button
                  fullWidth
                  variant="primary"
                  type="submit"
                  onClick={() => {}}
                  loading={isLoading}
                >
                  Sign In
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
      <div className="mt-10 text-center text-sm text-gray-500">
        Not a member?{" "}
        <Button
          paddingZero
          variant="transparent-primary"
          onClick={navigateToSignUp}
        >
          Sign up
        </Button>
      </div>
    </SpringContainer>
  );
};

export default Login;
