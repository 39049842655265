import React from "react";

const TermsAndConditions = () => {
  return (
    <p className="text-2xs text-gray-500">
      By signing up you agree to our{" "}
      <a
        href="https://www.articlex.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary"
      >
        Terms and Conditions
      </a>
    </p>
  );
};

export default TermsAndConditions;
