import classNames from "classnames";
import React from "react";
import LogoFullBlack from "../assets/images/article_x_black.png";
interface LogoProps {
  width?: string | number;
  className?: string;
}

export const Logo = ({ width, className }: LogoProps) => (
  <img
    src={LogoFullBlack}
    alt="article-x-logo"
    width={width ?? 160}
    className={classNames("mx-auto", className)}
  />
);

export default Logo;
