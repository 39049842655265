import { api } from "./api";
import { ARTICLE_DASHBOARD } from "../apiEndPoints";
import { generatePath } from "react-router-dom";

export const dashboardApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: ({ account_id, timezone }) => ({
        url:
          generatePath(ARTICLE_DASHBOARD, { account_id }) +
          `?timezone=${timezone}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApiSlice;
