import classNames from "classnames";
import React from "react";

type MessageProps = {
  message: string;
  type: "error" | "success";
  className?: string;
  style?: React.CSSProperties;
};

const Message = ({ message, type, className, style }: MessageProps) => {
  if (!message) {
    return null;
  }
  return (
    <div
      className={classNames(
        "h-10 p-4 my-4 rounded w-full flex items-center gap-1 text-sm",
        {
          "text-red-600": type === "error",
        },
        {
          "text-green-700 ": type === "success",
        },
        className
      )}
      style={style}
    >
      {message}
    </div>
  );
};

export default Message;
