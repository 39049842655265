import { FormikErrors } from "formik";
import React from "react";
import Input from "../../../components/Input";
import { Template } from "../../../models";
import TemplateContainer from "./common/TemplateContainer";

const TemplateName = ({
  values,
  error,
  setFieldValue,
}: {
  values: Template;
  error: string | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
}) => {
  return (
    <TemplateContainer>
      <Input
        label="Template Name"
        name="name"
        errorMessage={error}
        errorPosition="bottom-left"
        value={values?.name}
        placeholder="A meaningful name for this article group. Templates can generate one or more articles"
        tooltip="From this template your articles will be generated. Provide a meaningful name, for example, Achieve Personal Growth or Home Design Tips."
        onChange={({ target: { value } }) => setFieldValue("name", value)}
        onBlur={({ target: { value } }) => {
          setFieldValue("name", value.trim());
        }}
      />
    </TemplateContainer>
  );
};

export default TemplateName;
