import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import WordpressSVG from "../../../assets/svg/wordpress.svg";
import { ROUTE_DESTINATIONS_EDIT } from "../../../constants/routes";
import { Destination } from "../../../models/destination";
import DestinationActionButtons from "./DestinationActionButtons";

const DestinationCard = ({ destination }: { destination: Destination }) => {
  const navigate = useNavigate();
  const handleEdit = (destination_id: string) =>
    navigate(
      generatePath(ROUTE_DESTINATIONS_EDIT, {
        id: destination_id,
      })
    );

  return (
    <div
      className="w-full min-h-24 border rounded-lg overflow-hidden"
      onClick={() => handleEdit(destination.destination_id!)}
    >
      <div className="flex gap-2 items-center p-3">
        <img
          alt="wordpress-logo"
          src={WordpressSVG}
          className="w-10 h-10"
        />
        <div className="flex flex-col items-start flex-1 overflow-hidden">
          <div className="font-medium truncate w-full">{destination.name}</div>
          <a
            href={destination.destination.url}
            className="text-gray-500 hover:text-primary text-sm tracking-wide truncate w-full"
            rel="noreferrer"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {destination.destination.url}
          </a>
        </div>
      </div>
      <div className="border-t p-3">
        <DestinationActionButtons
          destinationId={destination["destination_id"]!}
        />
      </div>
    </div>
  );
};

export default DestinationCard;
