import { api } from "./api";
import { TEMPLATES, TEMPLATE_BY_ID } from "../apiEndPoints";
import { generatePath } from "react-router-dom";

export const templateApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createTemplate: builder.mutation({
      query: ({ account_id, ...rest }) => ({
        url: generatePath(TEMPLATES, { account_id }),
        method: "POST",
        body: rest,
      }),
      invalidatesTags: ["Templates"],
    }),
    getTemplates: builder.query({
      query: ({ account_id, offset, limit, include_total_count, search }) => {
        let url =
          generatePath(TEMPLATES, { account_id }) +
          `?offset=${offset}&limit=${limit}&include_total_count=${include_total_count}`;
        if (search) {
          url += `&search=${search}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: (result, error, { accountId }) => [
        { type: "Templates", accountId },
      ],
    }),
    getTemplateById: builder.query({
      query: ({ account_id, template_id }) => ({
        url: generatePath(TEMPLATE_BY_ID, {
          account_id,
          template_id,
        }),
        method: "GET",
      }),
      providesTags: (result, error, { accountId }) => [
        { type: "Templates", accountId },
      ],
    }),
    updateTemplateById: builder.mutation({
      query: ({ account_id, template_id, ...rest }) => ({
        url: generatePath(TEMPLATE_BY_ID, {
          account_id,
          template_id,
        }),
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: ["Templates"],
    }),
    deleteTemplateById: builder.mutation({
      query: ({ account_id, template_id }) => ({
        url: generatePath(TEMPLATE_BY_ID, {
          account_id,
          template_id,
        }),
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useGetTemplatesQuery,
  useLazyGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useDeleteTemplateByIdMutation,
  useUpdateTemplateByIdMutation,
} = templateApiSlice;
