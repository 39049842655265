import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Logo from "../../components/Logo";
import Message from "../../components/Message";
import { Heading, Text } from "../../components/Typography";
import { ROUTE_LOGIN } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { usePasswordResetMutation } from "../../redux/services/auth";
import PasswordValidator from "../SignUpEmail/components/PasswordValidator";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
      "Password must meet the guidelines"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const [message, setMessage] = useState("");
  const [resetError, setResetError] = useState("");
  const [passwordReset, { isLoading }] = usePasswordResetMutation();

  const handleLogin = useCallback(() => navigate(ROUTE_LOGIN), [navigate]);
  useEffect(() => {
    if (!token) {
      handleLogin();
    }
  }, [token, handleLogin]);

  const handleResetPassword = async (newPassword: string) => {
    setResetError("");
    setMessage("");
    await passwordReset({ token: token!, new_password: newPassword })
      .unwrap()
      .then((response) => {
        if (response?.status_code === 200) {
          setMessage(
            "Your password has been changed successfully. Returning to sign-in in 5 seconds."
          );
          setTimeout(() => handleLogin(), 5000);
        } else {
          setResetError("Something went wrong. Please contact support.");
        }
      })
      .catch((error: any) => {
        setResetError(
          error?.data?.detail || "Something went wrong. Please contact support."
        );
      });
  };
  return (
    <SpringContainer centered>
      <div className="mx-auto w-full max-w-md text-center">
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          onSubmit={(values: { password: string; confirmPassword: string }) =>
            handleResetPassword(values.password)
          }
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={resetPasswordSchema}
        >
          {(props) => {
            const { values, errors, setFieldValue } = props;
            return (
              <div className="mx-auto w-full text-center space-y-6">
                <Logo />
                <Heading size="xl">Reset Password</Heading>
                <Text className="text-sm text-gray-500">
                  Enter a strong and secure password
                </Text>
                <Message
                  type={message ? "success" : "error"}
                  message={message || resetError}
                />
                <Form className="space-y-6 max-w-sm mx-auto">
                  <div>
                    <Input
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={values.password}
                      errorMessage={errors.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    <PasswordValidator password={values.password} />
                  </div>
                  <Input
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    errorMessage={errors.confirmPassword}
                    onChange={(e) =>
                      setFieldValue("confirmPassword", e.target.value)
                    }
                  />
                  <Button
                    fullWidth
                    variant="primary"
                    type="submit"
                    onClick={() => {}}
                    loading={isLoading}
                  >
                    Change Password
                  </Button>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </SpringContainer>
  );
};

export default ResetPassword;
