import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import * as Yup from "yup";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Logo from "../../components/Logo";
import Message from "../../components/Message";
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import { Heading } from "../../components/Typography";
import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_PLANS,
} from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import { useLoginMutation, useVerifyMutation } from "../../redux/services/auth";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const VerifyEmail = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const email = encodeURIComponent(params.get("email") ?? "");
  const token = params.get("token");
  const [login, { isLoading: loadingLogin }] = useLoginMutation();
  const [verify, { isLoading: loadingVerify }] = useVerifyMutation();
  const [isVerified, setIsVerified] = useState(false);
  const [message, setMessage] = useState("");

  const verifyEmail = async () => {
    setMessage("");
    await verify({ email, token })
      .unwrap()
      .then((response) => {
        if (response?.status_code === 200 && response?.message) {
          setIsVerified(true);
          setMessage(response.message);
        }
      })
      .catch((error) => {
        setMessage(error?.data?.detail || "Something went wrong!");
      });
  };

  useMount(() => {
    if (token && email) {
      verifyEmail();
    }
  });

  const handleLogin = async (email: string, password: string) => {
    setMessage("");
    await login({
      username: email,
      password: password,
    })
      .unwrap()
      .then((response) => {
        if (response?.access_token) {
          navigate(ROUTE_PLANS, {
            replace: true,
          });
        } else {
          setMessage("Something went wrong!");
        }
      })
      .catch((error) => {
        if (error?.status === 400 && error?.data?.detail) {
          setMessage(error.data.detail);
        } else {
          setMessage("Something went wrong!");
        }
      });
  };

  const handleForgetPassword = () => navigate(ROUTE_FORGOT_PASSWORD);
  const handleBackToLogin = () => navigate(ROUTE_LOGIN);

  if (!token || !email) {
    navigate(ROUTE_LOGIN);
  }
  return (
    <SpringContainer centered>
      {loadingVerify && (
        <div className="flex flex-col gap-4">
          <Logo />
          <div className="flex items-center gap-2 my-4 p-3 rounded text-sm justify-center">
            <LoadingSpinner size="30" />
            <span>Verifying</span>
          </div>
        </div>
      )}
      {!loadingVerify && !isVerified && (
        <div className="flex flex-col gap-4 max-w-md mx-auto">
          <Logo />
          <Message
            type="error"
            message={message}
            className="justify-center"
          />
          <div className="text-center text-sm text-gray-500">
            Back to{" "}
            <Button
              paddingZero
              variant="transparent-primary"
              onClick={handleBackToLogin}
            >
              Sign In
            </Button>
          </div>
        </div>
      )}
      {isVerified && (
        <>
          <div className="mx-auto w-full text-center">
            <Logo />
            <Heading
              size="xl"
              className="my-4"
            >
              Enter your password to Sign In.
            </Heading>
            <Message
              type={message === "Email verified." ? "success" : "error"}
              message={message}
              className="justify-center"
            />
          </div>

          <Formik
            initialValues={{ email: decodeURIComponent(email), password: "" }}
            onSubmit={(values) => {
              handleLogin(values.email, values.password);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={loginSchema}
          >
            {(props) => {
              const { values, errors, setFieldValue } = props;
              return (
                <div className="mx-auto w-full max-w-sm">
                  <Form className="space-y-6">
                    <div>
                      <Input
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        value={values.password}
                        errorMessage={errors?.password}
                        onChange={(e: any) =>
                          setFieldValue("password", e.target.value)
                        }
                      />
                      <div className="mt-1 w-full text-right">
                        <Button
                          paddingZero
                          variant="transparent-primary"
                          onClick={handleForgetPassword}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => {}}
                      loading={loadingLogin}
                      className="block w-full"
                    >
                      Sign In
                    </Button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </>
      )}
    </SpringContainer>
  );
};

export default VerifyEmail;
