import React from "react";

export type Option = {
  id: any;
  name: any;
  description?: string;
};

type RadioGroupProps = {
  options: Option[];
  name: string;
  selected?: Option;
  onClick: (option: Option) => void;
};

const RadioGroup = ({ options, name, selected, onClick }: RadioGroupProps) => {
  return (
    <fieldset>
      <div className="flex flex-col md:flex-row gap-2 md:gap-5 md:items-center">
        {options.map((option: Option) => (
          <div
            key={option.id}
            className="relative flex items-start"
          >
            <div className="flex h-6 items-center">
              <input
                id={option.id}
                aria-describedby={`${option.id}-description`}
                name={name}
                type="radio"
                checked={selected?.id === option.id}
                // onClick={() => onClick(option)}
                onChange={() => onClick(option)}
                className="h-4 w-4 border-gray-300 text-primary focus:ring-primaryHover focus:ring-transparent"
              />
            </div>
            <div className="ml-2 text-sm leading-6">
              <label
                htmlFor={option.id}
                className="text-gray-600"
              >
                {option.name}
              </label>
              {option.description && (
                <div
                  id={`${option.id}-description`}
                  className="text-gray-500 text-xs"
                >
                  {option.description}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioGroup;
