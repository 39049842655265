import qs from "qs";
import { generatePath } from "react-router-dom";
import { API_CACHING_TAGS } from "../../constants/api";
import {
  LOGIN,
  PASSWORD_RECOVER,
  PASSWORD_RESET,
  REGISTER,
  RESEND_VERIFY_EMAIL,
  VERIFY_EMAIL,
} from "../apiEndPoints";
import { setAuthToken } from "../features/authSlice";
import { api } from "./api";

export const authApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: LOGIN,
        method: "POST",
        body: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }),
      transformResponse: (response: any) => response,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuthToken(data.access_token));
          dispatch(api.util.invalidateTags(API_CACHING_TAGS));
        } catch (error) {
          console.error("Login failed:", error);
        }
      },
    }),
    register: builder.mutation({
      query: (userDetails) => ({
        url: REGISTER,
        method: "POST",
        body: userDetails,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }),
    }),
    verify: builder.mutation({
      query: (data) => ({
        url: `${VERIFY_EMAIL}?email=${data.email}&token=${data.token}`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    resendVerificationEmail: builder.mutation({
      query: (email) => ({
        url: `${RESEND_VERIFY_EMAIL}/${email}`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: generatePath(PASSWORD_RECOVER, { email }),
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    passwordReset: builder.mutation({
      query: (data: { token: string; new_password: string }) => ({
        url: PASSWORD_RESET,
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyMutation,
  useForgotPasswordMutation,
  useResendVerificationEmailMutation,
  usePasswordResetMutation,
} = authApiSlice;
