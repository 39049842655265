import { animated, Spring } from "@react-spring/web";
import classNames from "classnames";
import React from "react";

const SpringContainer = ({
  children,
  centered,
  className,
  noPadding = false,
}: {
  children: any;
  centered?: boolean;
  className?: string;
  noPadding?: boolean;
}) => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
      {(props: any) => (
        <animated.div
          style={props}
          className={classNames(
            "h-full w-full overflow-auto no-scrollbar",
            {
              "flex flex-col items-center justify-center": centered,
            },
            { "p-0": noPadding },
            { "p-6": !noPadding },
            className
          )}
        >
          {children}
        </animated.div>
      )}
    </Spring>
  );
};

export default SpringContainer;
